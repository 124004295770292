export const PORTSCOLORCODES = [
  { Apapa: "#33FF57" },
  { Calabar: "#e32d2d" },
  { Lekki: "#3357FF" },
  { Onne: "#33FFF3" },
  { Tincan: "#00a758" },
  { Rivers: "#FFD433" },
  { Warri: "#FF33A1" },
];

export const PORTSLABELS = [
  "Apapa",
  "Tincan",
  "Lekki",
  "Warri",
  "Onne",
  "Calabar",
  "Rivers",
];
