import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Receipt from "../../components/receipt/Receipt";
import ErrorIcon from '@mui/icons-material/Error';

export default function ReceiptPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isInvalid, setIsInvalid] = useState(false);
    const { transactionId, userId } = useParams();

    useEffect(() => {
        const myHeaders = new Headers();
        
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/transactions/get-one/${transactionId}/${userId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    if(result?.data){
                        setData(result?.data);
                    } else {
                        setIsInvalid(true);
                    }
                } else {
                    setIsInvalid(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [transactionId, userId]);


    return (
        <>
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                    <CircularProgress color="success" />
                </Box>
            :
            <>
                {isInvalid ?
                    <div style={{color: "#FF0000", margin: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 3, marginTop: 20, fontSize: '24px', justifyItems: "center", fontWeight: 'bold'}}>
                        <ErrorIcon fontSize="large" /> 
                        This payment is invalid
                    </div>
                    :
                    <Receipt data={data} />
                }
            </>
            }
        </>
    );
}