import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import CustomInput from "../../components/input/CustomInput";
import CustomModal from "../../components/modal/CustomModal";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useLocation } from "react-router-dom";

export default function ReportsPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('name');
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [nameEdit, setNameEdit] = useState("");
    const [emailEdit, setEmailEdit] = useState("");
    const [phoneEdit, setPhoneEdit] = useState("");
    
    const tableAction = (row) => {
        return (
        <button 
            style={{backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0}} 
            onClick={() => {
                setCurrentItem(row);
                setNameEdit(row?.name);
                setEmailEdit(row?.email);
                setPhoneEdit(row?.phone);
                setOpenEditModal(true);
            }}>
            Edit
        </button>
        );
    };

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/customers/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);


    const statusType = {
        "active": 1,
        "inactive": 0
    };
    
    return(
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20}}>
                <Title>Reports {status && `(${status})`}</Title>
                <div style={{width: "150px"}}>
                    
                </div>
            </div>
            <ReusableTable 
                tableHeader={
                    [
                        { id: 'name', label: 'Name', numeric: false },
                        { id: 'phone', label: 'Phone', numeric: false },
                        { id: 'email', label: 'Email', numeric: false },
                    ]
                }
                tableData={status ? data.filter(i => i.status === statusType[status.toLowerCase()]) : data}
                tableAction={tableAction} 
            />
        </>
    );
}