import { Box, CircularProgress, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';

const COLORS = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
    '#A28EFF', '#FF6F91', '#FF9671', '#FFC75F',
    '#F9F871', '#36A2EB', '#4BC0C0', '#9966FF',
    '#FF9F40', '#FF6384', '#C9CBCF', '#4A90E2',
    '#50E3C2', '#B8E986', '#FF5E3A', '#FF9500'
];

export default function DashboardBarChart() {
    const navigate = useNavigate();
    const [selectedTabCategory, setSelectedTabCategory] = useState("tariff");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        let type = selectedTabCategory;
        const types = ['tariff', 'port', 'agent'];
        if (!types.includes(type)) {
            type = 'tariff';
        }
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        setLoading(true);
        setData([]);
        fetch(`${window.backendUrl}/api/v1/reports/items/${type}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [selectedTabCategory]);

    const handleClick = (data, index) => {
        if (selectedTabCategory === 'agent') {
            navigate(`/transactions?name=${encodeURIComponent(data?.createdBy)}&type=${encodeURIComponent(selectedTabCategory)}&agentName=${data?.name}`);
        } else {
            navigate(`/transactions?name=${encodeURIComponent(data?.name)}&type=${encodeURIComponent(selectedTabCategory)}`);
        }
    };

    return (
        <Wrapper>
            <TabsContainer>
                <TabsList>
                    <Tab
                        isSelected={selectedTabCategory === "tariff"}
                        onClick={() => setSelectedTabCategory("tariff")}
                    >
                        Tariffs
                    </Tab>
                    <Tab
                        isSelected={selectedTabCategory === "port"}
                        onClick={() => setSelectedTabCategory("port")}
                    >
                        Ports
                    </Tab>
                    <Tab
                        isSelected={selectedTabCategory === "agent"}
                        onClick={() => setSelectedTabCategory("agent")}
                    >
                        Agents
                    </Tab>
                </TabsList>
            </TabsContainer>
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <div style={{ overflowY: "hidden", overflowX: "auto", }}>
                    <center>
                        <BarChart
                            width={selectedTabCategory === 'tariff' ? 3000 : 1000}
                            height={500} data={data}
                            margin={{ top: 20, right: 20, left: 0, bottom: 200 }}  // Increased left and bottom margins
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-90} textAnchor="end" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="totalAmountGenerated" onClick={handleClick}>
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Bar>
                        </BarChart>
                    </center>
                </div>
            }
        </Wrapper>
    );
}

const Wrapper = styled("div")(({ }) => ({
    width: "100%",
    height: "auto",
    minHeight: '200px',
    border: '0px solid red',
}));

const TabsContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
}));

const TabsList = styled("ul")(() => ({
    display: "flex",
    gap: "2rem",
    borderBottom: "0.1rem solid #ccc",
    listStyle: "none",
}));

const Tab = styled("li")(({ isSelected }) => ({
    padding: "0.5rem",
    margin: "0.5rem",
    borderRadius: "0.25rem",
    borderBottom: `${isSelected ? "0.25rem solid green" : ""}`,
    color: `${isSelected ? "black" : "#6b7280"}`,
    cursor: "pointer",
}));