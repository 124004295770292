import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useReactToPrint } from "react-to-print";
import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from "../../components/alerts/alertUtils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Receipt from "../../components/receipt/Receipt";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function TransactionDetailPage() {
  const { transactionId, createdBy } = useParams();
  const profile = useSelector((store) => store.user.profile);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const contentRef = useRef(null);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.backendUrl}/api/v1/transactions/get-one/${transactionId}/${createdBy}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.code === 200) {
          setData(result?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const downloadPDF = () => {
    const input = document.getElementById("divToPrint");

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save(`receipt-${data?.transactionId}.pdf`);
      })
      .catch((error) => {
        console.error("Error generating PDF", error);
      });
  };

  // Function to trigger printing
  const handlePrint = useReactToPrint({ contentRef });

  return (
    <>
      <MessageAlert
        message={alertMessage}
        open={alertOpen}
        onClose={handleAlertClose(setAlertOpen)}
        type={alertType}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Title>Transaction Details</Title>
      </div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="success" />
        </Box>
      ) : (
        <>
          <table width={"100%"} border={0} cellPadding={10}>
            <tr>
              <td align="right" width={"50%"}>
                <label style={{ fontWeight: 600 }}>Port: </label>
              </td>
              <td>{data?.portName}</td>
            </tr>
            <tr>
              <td align="right">
                <label style={{ fontWeight: 600 }}>Transaction ID: </label>
              </td>
              <td>{data?.transactionId}</td>
            </tr>
            <tr>
              <td align="right">
                <label style={{ fontWeight: 600 }}>Customer: </label>
              </td>
              <td>
                {data?.customerName} ({data?.customerEmail})
              </td>
            </tr>
            <tr>
              <td align="right">
                <label style={{ fontWeight: 600 }}>Item: </label>
              </td>
              <td>{data?.itemName}</td>
            </tr>
            <tr>
              <td align="right">
                <label style={{ fontWeight: 600 }}>Quantity: </label>
              </td>
              <td>{data?.itemQuantity}</td>
            </tr>
            <tr>
              <td align="right">
                <label style={{ fontWeight: 600 }}>Amount: </label>
              </td>
              <td>
                {data?.amount
                  ?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
            <tr>
              <td align="right">
                <label style={{ fontWeight: 600 }}>Unit Of Measurement: </label>
              </td>
              <td>{data?.otherData?.unitOfMeasurement}</td>
            </tr>
            <tr>
              <td align="right">
                <label style={{ fontWeight: 600 }}>Payment Date: </label>
              </td>
              <td>{data?.createdAt}</td>
            </tr>
            <tr>
              <td align="right">
                <label style={{ fontWeight: 600 }}>Status: </label>
              </td>
              <td>{data?.status}</td>
            </tr>
            {data?.paymentData?.channel === "card" && (
              <>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Payment Channel: </label>
                  </td>
                  <td>{data?.paymentData?.channel}</td>
                </tr>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Card Type: </label>
                  </td>
                  <td>{data?.paymentData?.card_type}</td>
                </tr>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Exp Year: </label>
                  </td>
                  <td>{data?.paymentData?.exp_year}</td>
                </tr>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Exp Month: </label>
                  </td>
                  <td>{data?.paymentData?.exp_month}</td>
                </tr>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Bank Name: </label>
                  </td>
                  <td>{data?.paymentData?.bank}</td>
                </tr>
              </>
            )}
            {data?.paymentData?.channel === "bank" && (
              <>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Payment Channel: </label>
                  </td>
                  <td>{data?.paymentData?.channel}</td>
                </tr>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Bank Name: </label>
                  </td>
                  <td>{data?.paymentData?.bank}</td>
                </tr>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Account Name: </label>
                  </td>
                  <td>{data?.paymentData?.account_name}</td>
                </tr>
                <tr>
                  <td align="right">
                    <label style={{ fontWeight: 600 }}>Country: </label>
                  </td>
                  <td>{data?.paymentData?.country_code}</td>
                </tr>
              </>
            )}
          </table>
          <h3>Payment Receipt</h3>
          {profile.userType === "admin" ? (
            <div ref={contentRef} className="print-receipt">
              {["Original", "Duplicate", "Triplicate"].map((type) => (
                <div style={{ width: "100%" }}>
                  <Receipt data={data} type={type} />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <Receipt data={data} type="Original" />
            </div>
          )}
          <div style={{ width: "100%" }}>
            {profile.userType === "admin" ? (
              <div style={{ width: "200px", margin: "10px auto" }}>
                <CustomButton onClick={handlePrint}>Print</CustomButton>
              </div>
            ) : (
              <div style={{ width: "200px", margin: "10px auto" }}>
                <CustomButton onClick={() => downloadPDF()}>
                  Download
                </CustomButton>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
