import React, { useState, useEffect, useRef } from "react";

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useLocation } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import Logo from "../../assets/logo.png";

export default function ReportsByTariffPage() {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleClick = () => {

    };

    const tableAction = (row) => {
        return (
            <button
                style={{ backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0 }}
                onClick={() => {
                    console.log(row);
                }}>
                View
            </button>
        );
    };

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/reports/items/tariff`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    setData(result?.data);
                } else {

                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const PrintableComponent = () => {
        return (
            <div style={{border: '0px solid red', width: '100%', padding: '20px', boxSizing: 'border-box'}}>
                <div style={{textAlign: 'center'}}><img src={Logo} alt="Logo" width={100} style={{alignSelf: "center"}} /></div>
                <h2 style={{color: "#00701F", textAlign: "center", margin: 0, padding: 0}}>NIGERIAN PORTS AUTHORITY</h2>
                <h2 style={{textAlign: 'center', marginTop: 0, paddingTop: 0}}>Report</h2>
                <table width={"100%"}>
                    <tr>
                        <td align="left" style={{paddingBottom: '15px', width: "50%"}}><b>Total Items:</b> {data.length}</td>
                        <td align="right" style={{paddingBottom: '15px', width: "50%"}}><b>Total Amount:</b> {data.reduce((sum, tariff) => sum + (tariff.totalAmountGenerated || 0), 0)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    </tr>
                </table>
                <table width={"100%"} border={0}>
                    <tr className="header">
                        <td align="center">S/N</td>
                        <td align="center">Item</td>
                        <td align="center">Currency</td>
                        <td align="center">Amount</td>
                    </tr>
                    {data?.map((item, index) => (
                        <tr key={index} className={(index+1) % 2 === 0 ? 'even' : 'odd'}>
                            <td>{index+1}</td>
                            <td>{item?.name}</td>
                            <td>{item?.currency}</td>
                            <td>{item?.totalAmountGenerated?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                    ))}
                </table>
            </div>
        );
    }

    const contentRef = useRef(null);
    // Function to trigger printing
    const handlePrint = useReactToPrint({ contentRef });

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Reports by Tariff</Title>
                <div style={{ width: "150px" }}>

                </div>
            </div>
            <ReusableTable
                tableHeader={
                    [
                        { id: 'name', label: 'Name', numeric: false },
                        { id: 'currency', label: 'Currency', numeric: false },
                        { id: 'totalAmountGenerated', label: 'Amount Generated', numeric: true },
                    ]
                }
                tableData={data}
                tableAction={tableAction}
                isReport={true}
                reportTotal={data.reduce((sum, tariff) => sum + (tariff.totalAmountGenerated || 0), 0)}
                datePickerHandler={handleClick}
                printHandler={handlePrint}
            />
            <div ref={contentRef} className="print-only">
                <PrintableComponent />
            </div>
        </>
    );
}