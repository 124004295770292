import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Logo from "../../assets/logo.png";
import Grid from '@mui/material/Grid2';
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

export default function VerificationPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isInvalid, setIsInvalid] = useState(false);
    const { transactionId, userId } = useParams();

    useEffect(() => {
        const myHeaders = new Headers();
        
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/transactions/get-one/${transactionId}/${userId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    if(result?.data){
                        setData(result?.data);
                    } else {
                        setIsInvalid(true);
                    }
                } else {
                    setIsInvalid(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [transactionId, userId]);

    return (
        <>
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                    <CircularProgress color="success" />
                </Box>
            :
                <div style={{ width: "100%", overflow: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', boxSizing: 'border-box', border: '0px solid red'}}>
                    <img src={Logo} alt="Logo" width={100} style={{alignSelf: "center", marginTop: "20px"}} />
                    <h2 style={{color: "#00701F", margin: 0, padding: 0}}>NIGERIAN PORTS AUTHORITY</h2>
                    <h4 style={{color: "#00701F", margin: 0, padding: 0}}>Payment Verification</h4>
                    <Grid container spacing={5} sx={{marginTop: "20px", border: '0px solid green', width: "100%"}}>
                        <Grid size={{ xs: 12, md: 3 }}></Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <table width="100%" border={0} cellPadding={5}>
                                <tr>
                                    <td colSpan={2} align="center">
                                        {isInvalid ?
                                            <div style={{color: "#FF0000", margin: 0, display: "flex", flexDirection: "row", justifyContent: "center", gap: 3, justifyItems: "center", fontWeight: 'bold'}}><ErrorIcon color="#FF0000" /> This payment is invalid</div>
                                            :
                                            <div style={{color: "#4BB543", margin: 0, display: "flex", flexDirection: "row", justifyContent: "center", gap: 3, justifyItems: "center", fontWeight: 'bold'}}><VerifiedIcon color="#4BB543" /> This payment is verified</div>
                                        }
                                        </td>
                                </tr>
                                {!isInvalid && <>
                                <tr>
                                    <td align="right" width={"50%"}><b>TRANSACTION ID:</b></td>
                                    <td align="left">{data?.transactionId.toUpperCase()}</td>
                                </tr>
                                <tr>
                                    <td align="right"><b>PAYMENT PURPOSE:</b></td>
                                    <td align="left">{data?.itemName}</td>
                                </tr>
                                <tr>
                                    <td align="right"><b>QUANTITY:</b></td>
                                    <td align="left">{data?.itemQuantity}</td>
                                </tr>
                                <tr>
                                    <td align="right"><b>PORT:</b></td>
                                    <td align="left">{data?.portName}</td>
                                </tr>
                                <tr>
                                    <td align="right"><b>CUSTOMER NAME:</b></td>
                                    <td align="left">{data?.customerName}</td>
                                </tr>
                                <tr>
                                    <td align="right"><b>STATUS:</b></td>
                                    <td align="left">{data?.status}</td>
                                </tr>
                                <tr>
                                    <td align="right"><b>CURRENCY:</b></td>
                                    <td align="left">{data?.currency}</td>
                                </tr>
                                <tr>
                                    <td align="right"><b>AMOUNT:</b></td>
                                    <td align="left">{data?.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                                <tr>
                                    <td align="right"><b>PAYMENT CHANNEL:</b></td>
                                    <td align="left">Paystack (Online)</td>
                                </tr>
                                </>}
                            </table>
                        </Grid>
                        <Grid size={{ xs: 12, md: 3 }}></Grid>
                    </Grid>
                </div>
            }
        </>
    );
}