// Sample data arrays
const berths = ["AHT-1 Berth", "BHT-3 Berth", "CPT-2 Berth", "DPT-4 Berth"];
const vesselNames = ["BELLATRIX", "SIRIUS", "VEGA", "ALTAIR", "RIGEL", "POLARIS", "CANOPUS", "ANTARES"];
const cargoTypes = ["GENERAL CARGO", "CONTAINERS", "OIL TANKER", "BULK CARRIER", "PASSENGER SHIP"];
const agents = ["APM TERMINALS", "MAERSK", "MSC", "COSCO", "DP WORLD"];
const ports = ["Apapa", "Tincan", "Lekki", "Calabar", "Warri", "Onne", "Rivers"];

// Function to generate random coordinates within Atlantic bounds
function generateRandomCoordinates() {
  const minLat = -60.0;
  const maxLat = 60.0;
  const minLon = -70.0;
  const maxLon = 20.0;

  const latitude = parseFloat((Math.random() * (maxLat - minLat) + minLat).toFixed(6));
  const longitude = parseFloat((Math.random() * (maxLon - minLon) + minLon).toFixed(6));

  return { latitude, longitude };
}

// Define exclusion zones as rectangles or circles
const exclusionZones = [
  // Eastern Coast of North America (approximate rectangle)
  { type: 'rectangle', minLat: 15.0, maxLat: 60.0, minLon: -80.0, maxLon: -50.0 },
  
  // Western Coast of Europe and Africa (approximate rectangle)
  { type: 'rectangle', minLat: -60.0, maxLat: 60.0, minLon: -10.0, maxLon: 20.0 },
  
  // Caribbean Islands (approximate rectangle)
  { type: 'rectangle', minLat: 10.0, maxLat: 25.0, minLon: -80.0, maxLon: -60.0 },
  
  // Gulf of Mexico (optional exclusion)
  { type: 'rectangle', minLat: 18.0, maxLat: 31.0, minLon: -98.0, maxLon: -80.0 },
  
  // Azores Islands (circle)
  { type: 'circle', centerLat: 37.7412, centerLon: -25.6756, radiusKm: 50 },
  
  // Canary Islands (circle)
  { type: 'circle', centerLat: 28.0, centerLon: -15.5, radiusKm: 50 },
  
  // Bermuda Islands (circle)
  { type: 'circle', centerLat: 32.3078, centerLon: -64.7505, radiusKm: 50 },
  
  // Iceland (circle)
  { type: 'circle', centerLat: 64.9631, centerLon: -19.0208, radiusKm: 100 },
  
  // Greenland (circle) - optional, depending on desired exclusion
  { type: 'circle', centerLat: 72.0000, centerLon: -40.0000, radiusKm: 200 },
  
  // Add more exclusion zones as needed
];

// Helper function to convert degrees to radians
function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

// Function to calculate distance between two coordinates using Haversine formula
function getDistanceKm(lat1, lon1, lat2, lon2) {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
      Math.cos(degreesToRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadiusKm * c;
}

// Function to check if a point is within any exclusion zone
function isInExclusionZone(lat, lon) {
  for (const zone of exclusionZones) {
    if (zone.type === 'rectangle') {
      // Adjust rectangle boundaries if necessary
      if (
        lat >= zone.minLat &&
        lat <= zone.maxLat &&
        lon >= zone.minLon &&
        lon <= zone.maxLon
      ) {
        return true;
      }
    } else if (zone.type === 'circle') {
      const distance = getDistanceKm(lat, lon, zone.centerLat, zone.centerLon);
      if (distance <= zone.radiusKm) {
        return true;
      }
    }
  }
  return false;
}

// Function to generate random ship data
function generateShipData(latitude, longitude) {
  const imoNumber = Math.floor(Math.random() * 900000 + 100000).toString();
  const length = (Math.random() * (300 - 100) + 100).toFixed(1);
  const berthDate = `2024-${String(Math.floor(Math.random() * 12 + 1)).padStart(2, '0')}-${String(Math.floor(Math.random() * 28 + 1)).padStart(2, '0')}`;
  const etd = `2024-${String(Math.floor(Math.random() * 12 + 1)).padStart(2, '0')}-${String(Math.floor(Math.random() * 28 + 1)).padStart(2, '0')} ${String(Math.floor(Math.random() * 24)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}:00`;
  const rotation = Math.random() > 0.5 ? "YES" : "NO";
  const berth = berths[Math.floor(Math.random() * berths.length)];
  const vesselName = vesselNames[Math.floor(Math.random() * vesselNames.length)];
  const cargo = cargoTypes[Math.floor(Math.random() * cargoTypes.length)];
  const agent = agents[Math.floor(Math.random() * agents.length)];
  const port = ports[Math.floor(Math.random() * ports.length)];

  return {
    berth,
    vesselName,
    imoNumber,
    length,
    berthDate,
    etd,
    rotation,
    agent,
    cargo,
    port,
    latitude: latitude.toFixed(6),
    longitude: longitude.toFixed(6)
  };
}

// Function to generate 500 ships with ocean coordinates
function generateShips(count) {
  const ships = [];
  let attempts = 0;
  const maxAttempts = count * 20; // Increased to 20 to allow more iterations

  while (ships.length < count && attempts < maxAttempts) {
    const { latitude, longitude } = generateRandomCoordinates();

    // Ensure longitude is between -70 and 20
    if (longitude < -70 || longitude > 20) continue;

    // Check if the point is not in any exclusion zone
    if (!isInExclusionZone(latitude, longitude)) {
      const ship = generateShipData(latitude, longitude);
      ships.push(ship);
    }

    attempts++;
  }

  if (ships.length < count) {
    console.warn(`Only generated ${ships.length} ships out of requested ${count}. Consider adjusting exclusion zones or maxAttempts.`);
  }

  return ships;
}

// Generate 500 ships
const shippingScheduleData = generateShips(100);

export default shippingScheduleData;
