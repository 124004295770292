import PropTypes from "prop-types"
import { Button, CircularProgress } from '@mui/material';

export default function CustomButton(props) {
    const { disabled, loading, variant, startIcon, endIcon, size, onClick } = props;
    
    return(
        <Button
            disabled={disabled || loading}
            variant="contained" 
            endIcon={loading ? null : endIcon} 
            startIcon={loading ? null : startIcon}
            fullWidth 
            onClick={onClick}
            sx={
                variant === "outlined" ?
                {
                    textTransform: "none",
                    backgroundColor: 'transparent', 
                    border: '1px solid #00A758',
                    color: '#00A758',
                    height: size === "small" && '35px' || size === "medium" && '45px' || size === "large" && '55px' || size === undefined && '45px',
                    boxShadow: 0,
                    '&:hover': {
                        backgroundColor: 'transparent !important;'
                    },
                    '&:disabled': {
                        backgroundColor: 'transparent !important;',
                    }
                }
                :
                {
                    textTransform: "none",
                    backgroundColor: '#00A758', 
                    color: '#FFFFFF',
                    height: size === "small" && '35px' || size === "medium" && '45px' || size === "large" && '55px' || size === undefined && '45px',
                    boxShadow: 0,
                    '&:hover': {
                        backgroundColor: '#059862 !important;'
                    },
                    '&:disabled': {
                        backgroundColor: '#059862 !important;',
                    }
                }
            }
            >
            {loading ? <CircularProgress size="20px" sx={variant === "outlined" ? {color: '#04AA6D'} : {color: '#FFFFFF'}} /> :<>{props.children}</> }
        </Button>
    )
}
CustomButton.propTypes = {
    onClick: PropTypes.any,
    children: PropTypes.any,
    disabled: PropTypes.any,
    endIcon: PropTypes.any,
    loading: PropTypes.any,
    size: PropTypes.string,
    startIcon: PropTypes.any,
    variant: PropTypes.string
}
