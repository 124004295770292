const initialState = {
    sidebarCollapsed: false
};
   
export default (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_SIDEBAR': 
        return {
            ...state,
            sidebarCollapsed: action.payload,
        };
        
        default:
            return state;
    }
};