import React from "react";
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';

import "./Receipt.css";

const Receipt = ({ data, type }) => {
    
    function getReceiptBg() {
        switch (type) {
          case "Original":
            return "receipt-original";
          case "Duplicate":
            return "receipt-duplicate";
          case "Triplicate":
            return "receipt-triplicate";
          default:
            return ""; 
        }
      }

    return (
        <div style={{ width: "100%", overflow: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <div className={`receipt-container ${getReceiptBg()}`} id="divToPrint">
                <table border={0} width="100%">
                    <tr>
                        <td width="33%">
                            {/* <img src={QRImage} width="90px" alt="QRCode" /> */}
                            <QRCode 
                                style={{ height: "auto", maxWidth: "90px", width: "90px" }}
                                value={`https://npa.ereg.ng/#/transaction/verify/id/${data?.transactionId}/userid/${data?.createdBy}`} 
                            />
                        </td>
                        <td width="33%" align="center" valign="bottom"><b>NIGERIAN PORTS AUTHORITY</b></td>
                        <td width="33%" align="right"><b>DATE: </b>{data?.createdAt.substring(0, 10)} {data?.createdAt.substring(11, 16)}</td>
                    </tr>
                </table>
                <p>{type?.toUpperCase()}</p>
                <br />
                <table border={0} width="100%" cellPadding={5}>
                    <tr>
                        <td width="25%" height="20px"><b>TRANSACTION ID:</b></td>
                        <td width="25%">{data?.transactionId.toUpperCase()}</td>
                        <td width="25%"><b>PAYMENT REFERENCE:</b></td>
                        <td width="25%">{data?.transactionId.toUpperCase()}</td>
                    </tr>
                    <tr>
                        <td width="25%"><b>PAYMENT PURPOSE:</b></td>
                        <td width="25%">{data?.itemName}</td>
                        <td width="25%"><b>PORT:</b></td>
                        <td width="25%">{data?.portName}</td>
                    </tr>
                    <tr>
                        <td width="25%"><b>CUSTOMER NAME</b></td>
                        <td width="25%">{data?.customerName}</td>
                        <td width="25%"><b>STATUS</b></td>
                        <td width="25%">{data?.status}</td>
                    </tr>
                    <tr>
                        <td width="25%"><b>QUANTITY</b></td>
                        <td width="25%">{data?.itemQuantity}</td>
                        <td width="25%"><b>PAYMENT CHANNEL</b></td>
                        <td width="25%">{data?.paymentData?.channel ? data?.paymentData?.channel : "Online" }</td>
                    </tr>
                    <tr>
                        <td width="25%"><b>UNIT / MEASUREMENT</b></td>
                        <td width="25%">{data?.otherData?.unitOfMeasurement}</td>
                        <td width="25%"><b>RATE PER UNIT</b></td>
                        <td width="25%">{data?.amount / data?.itemQuantity}</td>
                    </tr>
                    <tr>
                        <td width="25%"><b>CURRENCY</b></td>
                        <td width="25%">{data?.currency}</td>
                        <td colSpan={2} rowSpan={2} align="center" style={{padding: 0,}}>
                            <Barcode 
                                height={"40px"}
                                background="transparent"
                                value={data?.transactionId.toUpperCase()} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <td width="25%"><b>AMOUNT</b></td>
                        <td width="25%">{data?.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    </tr>
                    <tr>
                        <td width="25%"><b>CASHIER</b></td>
                        <td width="25%">{data?.cashier ? data?.cashier : "Paid by Agent"}</td>
                        <td colSpan={2} align="center"><b>AUTHORITY</b></td>
                    </tr>
                </table>
            </div>
        </div>
    );
};

export default Receipt;
