import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import shipIconInImage from "../map/shipIconIn.png";
import shipIconOutImage from "../map/shipIconOut.png";

const shipIconIn = new L.Icon({
  iconUrl: shipIconInImage,
  iconSize: [20, 20],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const shipIconOut = new L.Icon({
  iconUrl: shipIconOutImage,
  iconSize: [20, 20],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const portCoordinates = {
  all: [5.6901, 5.2552],
  apapa: [6.4416, 3.3915],
  tincan: [6.4499, 3.3380],
  lekki: [6.4226, 3.5265],
  calabar: [4.9583, 8.3269],
  warri: [5.5177, 5.7504],
  onne: [4.7000, 7.1500],
  rivers: [4.7774, 7.0134]
};

const ShipMap = ({ ships, port }) => (
  <MapContainer style={{ height: "500px", width: "100%" }} center={portCoordinates[port] ? portCoordinates[port] : [6.4, 3.4]} zoom={port === "all" ? 6 : 12}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution="&copy; OpenStreetMap contributors"
    />
    {ships?.map((ship, index) => (
      <Marker
        key={index}
        position={[ship.lat, ship.lon]}
        icon={
          (
            ship?.destination?.toUpperCase().includes("LAGOS") || 
            ship?.destination?.toUpperCase().includes("APAPA") || 
            ship?.destination?.toUpperCase().includes("NG ")
          ) ? shipIconIn : shipIconOut}
      >
        <Popup>
          <div>
            <strong>Ship Name:</strong> {ship.name}<br/>
            <strong>MMSI:</strong> {ship.mmsi}<br/>
            <strong>Type:</strong> {ship.type}<br/>
            <strong>Destination:</strong> {ship.destination}<br/>
            <strong>Speed:</strong> {ship.speed}<br/>
            <strong>ETA:</strong> {ship.eta_UTC}<br/>
            <strong>Last Position:</strong> {ship.last_position_UTC}<br/>
            <strong>Latitude:</strong> {ship.lat}<br/>
            <strong>Longitude:</strong> {ship.lon}<br/>
            <strong>Movement:</strong> {
                (
                  ship?.destination?.toUpperCase().includes("LAGOS") || 
                  ship?.destination?.toUpperCase().includes("APAPA") || 
                  ship?.destination?.toUpperCase().includes("NG ")
                ) ? <spand style={{color: 'green'}}>In Comming</spand> : <spand style={{color: 'red'}}>Out Going</spand>
              } <br />
            <strong>Country:</strong> {ship.country_iso}<br/>
          </div>
        </Popup>
      </Marker>
    ))}
  </MapContainer>
);

export default ShipMap;
