import React, { useState, useEffect } from "react";

import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import { styled } from "@mui/system";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ShipMap from "../../components/map/ShipMap";
import shippingScheduleData from "../../utils/generateShips";
import vessels from "./vessels";

// const shippingScheduleData = [
//     {
//         "berth": "AHT-1 Berth",
//         "vesselName": "BELLATRIX",
//         "imoNumber": "344575",
//         "length": "159.8",
//         "berthDate": "2023-08-24",
//         "etd": "2023-08-26 15:37:00",
//         "rotation": "NO",
//         "agent": "APM TERMINALS",
//         "cargo": "GENERAL CARGO",
//         "port": "Apapa"
//     },
//     {
//         "berth": "AHT-2 Berth",
//         "vesselName": "ELARA MOON",
//         "imoNumber": "1004797",
//         "length": "153.7",
//         "berthDate": "2023-08-24",
//         "etd": "2023-08-26 15:37:00",
//         "rotation": "NO",
//         "agent": "APM TERMINALS",
//         "cargo": "GENERAL CARGO",
//         "port": "Apapa"
//     },
//     {
//         "berth": "AHT-3 Berth",
//         "vesselName": "MACAULAY",
//         "imoNumber": "242275",
//         "length": "120",
//         "berthDate": "2023-08-24",
//         "etd": "2023-08-26 15:37:00",
//         "rotation": "NO",
//         "agent": "APM TERMINALS",
//         "cargo": "GENERAL CARGO",
//         "port": "Tincan"
//     },
// ];

const TabsContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    marginBottom: "0.5rem",
    marginTop: 0,
    paddingTop: 0,
}))

const TabsList = styled("ul")(() => ({
    display: "flex",
    gap: "2rem",
    borderBottom: "0.1rem solid #ccc",
    listStyle: "none",
}))

const Tab = styled("li")(({ isSelected }) => ({
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    margin: "0.5rem",
    borderRadius: "0.25rem",
    borderBottom: `${isSelected ? "0.25rem solid green" : ""}`,
    color: `${isSelected ? "black" : "#6b7280"}`,
    cursor: "pointer",
}));


export default function TrackingPage() {
    const [selectedTab, setSelectedTab] = useState('all');
    const [filteredData, setFilteredData] = useState(shippingScheduleData);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        //setFilteredData(shippingScheduleData.filter((item) => item.port === tab));
    };

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };
        //setLoading(true);
        fetch(`${window.backendUrl}/api/v1/tracking/vessels`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    if(result?.data?.data?.vessels){
                        setData(result?.data?.data?.vessels);
                    } else {
                        setData(vessels);
                    }
                } else {
                    setData(vessels);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [selectedTab]);

    const portCoordinates = {
        all: { lat: 5.6901, lon: 5.2552 },
        apapa: { lat: 6.4416, lon: 3.3915 },
        tincan: { lat: 6.4499, lon: 3.3380 },
        lekki: { lat: 6.4226, lon: 3.5265 },
        calabar: { lat: 4.9583, lon: 8.3269 },
        warri: { lat: 5.5177, lon: 5.7504 },
        onne: { lat: 4.7000, lon: 7.1500 },
        rivers: { lat: 4.7774, lon: 7.0134 }
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 0 }}>
                <Title>Tracking</Title>
            </div>
            {loading ?
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <>
                    <TabsContainer>
                        <TabsList>
                            <Tab
                                isSelected={selectedTab === "all"}
                                onClick={() => handleTabClick("all")}
                            >
                                All
                            </Tab>
                            <Tab
                                isSelected={selectedTab === "apapa"}
                                onClick={() => handleTabClick("apapa")}
                            >
                                Apapa
                            </Tab>
                            <Tab
                                isSelected={selectedTab === "tincan"}
                                onClick={() => handleTabClick("tincan")}
                            >
                                Tincan
                            </Tab>
                            <Tab
                                isSelected={selectedTab === "lekki"}
                                onClick={() => handleTabClick("lekki")}
                            >
                                Lekki
                            </Tab>
                            <Tab
                                isSelected={selectedTab === "calabar"}
                                onClick={() => handleTabClick("calabar")}
                            >
                                Calabar
                            </Tab>
                            <Tab
                                isSelected={selectedTab === "warri"}
                                onClick={() => handleTabClick("warri")}
                            >
                                Warri
                            </Tab>
                            <Tab
                                isSelected={selectedTab === "onne"}
                                onClick={() => handleTabClick("onne")}
                            >
                                Onne
                            </Tab>
                            <Tab
                                isSelected={selectedTab === "rivers"}
                                onClick={() => handleTabClick("rivers")}
                            >
                                Rivers
                            </Tab>
                        </TabsList>
                    </TabsContainer>
                    <div style={{ zIndex: 9 }}>
                        <div style={{ width: '100%', height: '500px' }}>
                            <iframe
                                src={`https://www.vesselfinder.com/aismap?zoom=${selectedTab === "all" ? 3 : 8}&lat=${portCoordinates[selectedTab]?.lat}&lon=${portCoordinates[selectedTab]?.lon}`}
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                allowFullScreen
                                title="VesselFinder Map"
                            ></iframe>
                            <div className="header-overlay"></div>
                        </div>
                        {/* <ShipMap ships={data?.data?.vessels} port={selectedTab} /> */}
                    </div>
                    {/* <TabsContainer>
                        <TabsList>
                            <Tab isSelected={selectedTab === 'Apapa'} onClick={() => handleTabClick('Apapa')}>Apapa</Tab>
                            <Tab isSelected={selectedTab === 'Tincan'} onClick={() => handleTabClick('Tincan')}>Tincan</Tab>
                            <Tab isSelected={selectedTab === 'Lekki'} onClick={() => handleTabClick('Lekki')}>Lekki</Tab>
                            <Tab isSelected={selectedTab === 'Calabar'} onClick={() => handleTabClick('Calabar')}>Calabar</Tab>
                            <Tab isSelected={selectedTab === 'Warri'} onClick={() => handleTabClick('Warri')}>Warri</Tab>
                            <Tab isSelected={selectedTab === 'Onne'} onClick={() => handleTabClick('Onne')}>Onne</Tab>
                            <Tab isSelected={selectedTab === 'Rivers'} onClick={() => handleTabClick('Rivers')}>Rivers</Tab>
                        </TabsList>
                    </TabsContainer> */}
                    <br />
                    <Title>Ships</Title>
                    <ReusableTable
                        tableHeader={
                            [
                                { id: 'name', label: 'Name', numeric: false },
                                { id: 'mmsi', label: 'MMSI', numeric: false },
                                { id: 'imo', label: 'IMO_Number', numeric: false },
                                { id: 'country_iso', label: 'Country', numeric: false },
                                { id: 'destination', label: 'Destination', numeric: false },
                                { id: 'type', label: 'Type', numeric: false },
                                { id: 'lat', label: 'Latitude', numeric: false },
                                { id: 'lon', label: 'Longitude', numeric: false },
                                { id: 'speed', label: 'Speed', numeric: false },
                                { id: 'course', label: 'Course', numeric: false },
                                { id: 'heading', label: 'Heading', numeric: false },
                                { id: 'last_position_UTC', label: 'Last_Position', numeric: false },
                                { id: 'eta_UTC', label: 'ETA', numeric: false },
                                { id: 'distance', label: 'Distance', numeric: false },
                            ]
                        }
                        tableData={data ? data : []}
                    // tableAction={tableAction} 
                    />
                </>
            }
        </>
    );
}