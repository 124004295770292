import React from "react";
import { styled } from "@mui/system";
import { Outlet } from "react-router-dom";

//import Footer from "../components/footer/FooterComponent";

const AuthLayout = () => {
    return (
        <LayoutWrapper>
            <PageWrapper>
                <ContentWrapper>
                    <Outlet />
                </ContentWrapper>
                {/* <Footer /> */}
            </PageWrapper>
        </LayoutWrapper>
    )
}

const LayoutWrapper = styled('div')(() => ({
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-start',
}));

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#F5F8F9',
    width: '100%',
    height: '100vh',
    padding: '0rem 0rem',
}));

const ContentWrapper = styled('div')(() => ({
    backgroundColor: '#F5F8F9',
    padding: '0rem',
}));


export default AuthLayout;