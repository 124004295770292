export default [
    {
        "uuid": "ec77a7b4-e7d1-853a-bb5f-7445687b0558",
        "name": "BEGER ENDURANCE",
        "mmsi": "657243000",
        "imo": "9468695",
        "eni": null,
        "country_iso": "NG",
        "type": "High Speed Craft",
        "type_specific": "Pusher Tug",
        "lat": 6.46502,
        "lon": 3.375525,
        "speed": 0,
        "course": 171,
        "heading": null,
        "destination": "LAGOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730402760,
        "eta_UTC": "2024-10-31T19:26:00Z",
        "distance": 3.57197310311125
    },
    {
        "uuid": "cafc44bb-fb65-d8b8-3c4e-052f40c8ef05",
        "name": "OMS OKUN",
        "mmsi": "657201900",
        "imo": "9888833",
        "eni": null,
        "country_iso": "NG",
        "type": "Law Enforce",
        "type_specific": "Patrol Vessel",
        "lat": 6.458763,
        "lon": 3.371762,
        "speed": 0,
        "course": 350,
        "heading": 10,
        "destination": "LAGOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1706508300,
        "eta_UTC": "2024-01-29T06:05:00Z",
        "distance": 3.96581729788565
    },
    {
        "uuid": "52b54ecf-daa9-d797-8012-6399d49f58bb",
        "name": "ALFRED TEMILE 10",
        "mmsi": "310842000",
        "imo": "9937127",
        "eni": null,
        "country_iso": "BM",
        "type": "Tanker",
        "type_specific": "LPG Tanker",
        "lat": 6.456565,
        "lon": 3.371857,
        "speed": 0,
        "course": 256,
        "heading": 0,
        "destination": "NGLAG",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": 1730403960,
        "eta_UTC": "2024-10-31T19:46:00Z",
        "distance": 4.0963757150425
    },
    {
        "uuid": "d8d58e73-7d77-f056-d80a-6a25d00da264",
        "name": "NCC NASMA",
        "mmsi": "403515001",
        "imo": "9459008",
        "eni": null,
        "country_iso": "SA",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.45572,
        "lon": 3.37594,
        "speed": 0,
        "course": 303,
        "heading": 13,
        "destination": "WAF ARM GUARD",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": 1730230740,
        "eta_UTC": "2024-10-29T19:39:00Z",
        "distance": 4.12820430266333
    },
    {
        "uuid": "5b3da238-4e4f-14bd-fdef-579931947ad6",
        "name": "MORIARTY",
        "mmsi": "636024087",
        "imo": "9858802",
        "eni": null,
        "country_iso": "LR",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.454605,
        "lon": 3.37205,
        "speed": 0,
        "course": 305,
        "heading": 346,
        "destination": "LAGOS NIGERIA",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730504220,
        "eta_UTC": "2024-11-01T23:37:00Z",
        "distance": 4.21221880574991
    },
    {
        "uuid": "4924ee26-f6b6-542c-885b-f6e6685dcaca",
        "name": "PC LAGOS I",
        "mmsi": "657246700",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Port Tender",
        "type_specific": "Port Tender",
        "lat": 6.451105,
        "lon": 3.373642,
        "speed": 4.6,
        "course": 66,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 4.41318376900337
    },
    {
        "uuid": "5bda5029-0400-81c6-4928-bea9bd48c827",
        "name": "SEVEN INAGHA",
        "mmsi": "657175100",
        "imo": "9618094",
        "eni": null,
        "country_iso": "NG",
        "type": "Pilot Vessel",
        "type_specific": "Platform",
        "lat": 6.450933,
        "lon": 3.385745,
        "speed": 0,
        "course": 253,
        "heading": 24,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1727427120,
        "eta_UTC": "2024-09-27T08:52:00Z",
        "distance": 4.42828454369645
    },
    {
        "uuid": "da68f63e-92be-b7ef-a4fc-13d795eaa00f",
        "name": "BLUE LATITUDE",
        "mmsi": "657247800",
        "imo": "9171254",
        "eni": null,
        "country_iso": "NG",
        "type": "Dredger",
        "type_specific": "Utility Vessel",
        "lat": 6.45063,
        "lon": 3.373145,
        "speed": 0,
        "course": 21,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730302800,
        "eta_UTC": "2024-10-30T15:40:00Z",
        "distance": 4.44393490752486
    },
    {
        "uuid": "fbef2df3-00a4-9fd1-78d4-e6f8cfc06379",
        "name": "UROMI",
        "mmsi": "657119300",
        "imo": "9706671",
        "eni": null,
        "country_iso": "NG",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.450575,
        "lon": 3.37335,
        "speed": 0,
        "course": 205,
        "heading": null,
        "destination": "LAGOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1729878540,
        "eta_UTC": "2024-10-25T17:49:00Z",
        "distance": 4.4462496219294
    },
    {
        "uuid": "fd509569-22d5-8f80-232e-4fd37460bc7e",
        "name": "ANTELOPE",
        "mmsi": "657245200",
        "imo": "9531624",
        "eni": null,
        "country_iso": "NG",
        "type": "Dredger",
        "type_specific": "Tug",
        "lat": 6.45045,
        "lon": 3.37395,
        "speed": 0,
        "course": 322,
        "heading": 154,
        "destination": "APAPA 1",
        "last_position_epoch": 1730540160,
        "last_position_UTC": "2024-11-02T09:36:00Z",
        "eta_epoch": 1730384340,
        "eta_UTC": "2024-10-31T14:19:00Z",
        "distance": 4.45106989455787
    },
    {
        "uuid": "cdb82609-b0e2-0498-1d0b-e1c66e1d9934",
        "name": "EMEKUKU",
        "mmsi": "657011005",
        "imo": "9252670",
        "eni": null,
        "country_iso": "NG",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.450415,
        "lon": 3.373555,
        "speed": 0.1,
        "course": null,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1722187980,
        "eta_UTC": "2024-07-28T17:33:00Z",
        "distance": 4.45488545461693
    },
    {
        "uuid": "568c6888-d655-190d-8b25-4193d10764fc",
        "name": "S.D.GUMEL",
        "mmsi": "657131200",
        "imo": "9151735",
        "eni": null,
        "country_iso": "NG",
        "type": "Dredger",
        "type_specific": "Trailing Suction Hopper Dredger",
        "lat": 6.450227,
        "lon": 3.37401,
        "speed": 0,
        "course": null,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1707977340,
        "eta_UTC": "2024-02-15T06:09:00Z",
        "distance": 4.46417628949748
    },
    {
        "uuid": "8277384c-526f-d9d9-a9c9-a324347f3259",
        "name": "OREN",
        "mmsi": "657248200",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Dredger",
        "type_specific": "Dredger",
        "lat": 6.44992,
        "lon": 3.373543,
        "speed": null,
        "course": null,
        "heading": 52,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1724753940,
        "eta_UTC": "2024-08-27T10:19:00Z",
        "distance": 4.48457513622788
    },
    {
        "uuid": "d26b4753-90a7-03f3-05c8-aadd575370c5",
        "name": "WB 9",
        "mmsi": "657970000",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Port Tender",
        "type_specific": "Port Tender",
        "lat": 6.449815,
        "lon": 3.373902,
        "speed": 0.3,
        "course": 329,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 4.48930298093344
    },
    {
        "uuid": "8bcdf9e5-9848-87fc-ea5d-e54983a8e2a2",
        "name": "SEA VALOUR",
        "mmsi": "657264600",
        "imo": "9375070",
        "eni": null,
        "country_iso": "NG",
        "type": "Passenger",
        "type_specific": "Offshore Supply Ship",
        "lat": 6.449924,
        "lon": 3.388493,
        "speed": 0,
        "course": 53,
        "heading": null,
        "destination": "NIWA",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730471700,
        "eta_UTC": "2024-11-01T14:35:00Z",
        "distance": 4.50585423869927
    },
    {
        "uuid": "4c629ba0-2279-c12f-54cc-b4dfb1e5f8d5",
        "name": "MEDIATOR",
        "mmsi": "657131400",
        "imo": "9755610",
        "eni": null,
        "country_iso": "NG",
        "type": "High Speed Craft",
        "type_specific": "Patrol Vessel",
        "lat": 6.448948,
        "lon": 3.389797,
        "speed": 0,
        "course": 321,
        "heading": 114,
        "destination": "EKPE& ASBO FIELD",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 4.57411605149664
    },
    {
        "uuid": "e289f650-f97d-5407-e10f-9cf73255ce4e",
        "name": "OMAMBALA RIVER",
        "mmsi": "657267000",
        "imo": "9244128",
        "eni": null,
        "country_iso": "NG",
        "type": "Sailing Vessel",
        "type_specific": "Crew Boat",
        "lat": 6.448812,
        "lon": 3.389912,
        "speed": 0,
        "course": 212,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 4.5831549705193
    },
    {
        "uuid": "61aba4cd-5c06-2337-038a-7c43365f6d0a",
        "name": "PHILLIP BAY",
        "mmsi": "538005705",
        "imo": "9714757",
        "eni": null,
        "country_iso": "MH",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.447063,
        "lon": 3.376533,
        "speed": 0,
        "course": 304,
        "heading": 298,
        "destination": "NG APP",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730485800,
        "eta_UTC": "2024-11-01T18:30:00Z",
        "distance": 4.64612139422357
    },
    {
        "uuid": "2107023f-6152-320b-20ed-fe07df45fb5b",
        "name": "MAMAYITA",
        "mmsi": "657282900",
        "imo": "1032256",
        "eni": null,
        "country_iso": "NG",
        "type": "Passenger",
        "type_specific": "Offshore Supply Ship",
        "lat": 6.447393,
        "lon": 3.392233,
        "speed": 0,
        "course": 40,
        "heading": 291,
        "destination": "TAICHUNG",
        "last_position_epoch": 1730540160,
        "last_position_UTC": "2024-11-02T09:36:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 4.68850027952272
    },
    {
        "uuid": "ea3a4de7-b2a8-ccc9-e67a-da82292f7801",
        "name": "RISING SKY.GR",
        "mmsi": "538011037",
        "imo": "9782259",
        "eni": null,
        "country_iso": "MH",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.444907,
        "lon": 3.380213,
        "speed": 0,
        "course": 283,
        "heading": 299,
        "destination": "NG LOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1730750400,
        "eta_UTC": "2024-11-04T20:00:00Z",
        "distance": 4.77322747890033
    },
    {
        "uuid": "3cf30e82-8033-67a9-2c2f-28f606e494a5",
        "name": "COOL GIRL",
        "mmsi": "354294000",
        "imo": "9194921",
        "eni": null,
        "country_iso": "PA",
        "type": "Cargo",
        "type_specific": "Reefer",
        "lat": 6.443622,
        "lon": 3.382967,
        "speed": 0,
        "course": 259,
        "heading": 293,
        "destination": "NG LOS",
        "last_position_epoch": 1730540100,
        "last_position_UTC": "2024-11-02T09:35:00Z",
        "eta_epoch": 1730194620,
        "eta_UTC": "2024-10-29T09:37:00Z",
        "distance": 4.85520135146855
    },
    {
        "uuid": "5aefbd85-7e94-8b5b-6aec-73576a0b13ea",
        "name": "BI XUN",
        "mmsi": "412128000",
        "imo": "9566930",
        "eni": null,
        "country_iso": "CN",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.44333,
        "lon": 3.384025,
        "speed": 0,
        "course": 102,
        "heading": 112,
        "destination": "LOS,NG",
        "last_position_epoch": 1730540100,
        "last_position_UTC": "2024-11-02T09:35:00Z",
        "eta_epoch": 1729174320,
        "eta_UTC": "2024-10-17T14:12:00Z",
        "distance": 4.87603321963037
    },
    {
        "uuid": "7e95203b-3536-2a73-2fb6-f1983f91ca7d",
        "name": "ORANGE FROST",
        "mmsi": "306883000",
        "imo": "9797656",
        "eni": null,
        "country_iso": "CW",
        "type": "Other",
        "type_specific": "Reefer",
        "lat": 6.442002,
        "lon": 3.386738,
        "speed": 0,
        "course": 0,
        "heading": 291,
        "destination": "NGLOS",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": 1730206920,
        "eta_UTC": "2024-10-29T13:02:00Z",
        "distance": 4.9676603201987
    },
    {
        "uuid": "9f458a44-4bf5-ca66-32d6-8c79c0d3b331",
        "name": "PRINCESS MILLER",
        "mmsi": "259849000",
        "imo": "7825473",
        "eni": null,
        "country_iso": "NO",
        "type": "Cargo",
        "type_specific": "Offshore Supply Ship",
        "lat": 6.44292,
        "lon": 3.399035,
        "speed": 0.3,
        "course": 41,
        "heading": null,
        "destination": "NGLOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1726841700,
        "eta_UTC": "2024-09-20T14:15:00Z",
        "distance": 5.03321871931629
    },
    {
        "uuid": "7f19e98c-ad6c-7419-bfbc-de4be84a242f",
        "name": "COSCO JINGGANGSHAN",
        "mmsi": "477815400",
        "imo": "9418315",
        "eni": null,
        "country_iso": "HK",
        "type": "Cargo",
        "type_specific": "Timber Carrier",
        "lat": 6.440203,
        "lon": 3.391242,
        "speed": 0,
        "course": 124,
        "heading": 292,
        "destination": "LAGOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730022060,
        "eta_UTC": "2024-10-27T09:41:00Z",
        "distance": 5.106067367902
    },
    {
        "uuid": "5c3bd09e-d2ca-8d47-59e5-79d6bef9bb0c",
        "name": "SEAFOX PROLINE",
        "mmsi": "256119982",
        "imo": null,
        "eni": null,
        "country_iso": "MT",
        "type": "Pleasure Craft",
        "type_specific": "Pleasure Craft",
        "lat": 6.442733,
        "lon": 3.409713,
        "speed": 0,
        "course": null,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 5.23035643523796
    },
    {
        "uuid": "f144ce85-ae98-46bf-f462-10eef81dde83",
        "name": "MARMARIS M",
        "mmsi": "352002287",
        "imo": "9450791",
        "eni": null,
        "country_iso": "PA",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.438694,
        "lon": 3.395035,
        "speed": 0,
        "course": 255,
        "heading": 293,
        "destination": "NG LOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1729532880,
        "eta_UTC": "2024-10-21T17:48:00Z",
        "distance": 5.23187246861836
    },
    {
        "uuid": "a6d9fd58-3eac-0d0b-8b20-c2ef1a9ce0b9",
        "name": "CALYPSO N",
        "mmsi": "538006275",
        "imo": "9711444",
        "eni": null,
        "country_iso": "MH",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.437307,
        "lon": 3.382642,
        "speed": 0,
        "course": 278,
        "heading": 266,
        "destination": "NGLOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1729009620,
        "eta_UTC": "2024-10-15T16:27:00Z",
        "distance": 5.23318719756902
    },
    {
        "uuid": "68b68418-f89d-31ca-097f-8c0e5feabb26",
        "name": "STAVROS",
        "mmsi": "215468000",
        "imo": "9425875",
        "eni": null,
        "country_iso": "MT",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.437163,
        "lon": 3.380555,
        "speed": 0,
        "course": 26,
        "heading": 265,
        "destination": "NG LOS",
        "last_position_epoch": 1730540160,
        "last_position_UTC": "2024-11-02T09:36:00Z",
        "eta_epoch": 1730376540,
        "eta_UTC": "2024-10-31T12:09:00Z",
        "distance": 5.23842670094322
    },
    {
        "uuid": "02a00099-73cc-fc4b-e5cf-91a5595a7c63",
        "name": "MAJIYA",
        "mmsi": "657119200",
        "imo": "9706669",
        "eni": null,
        "country_iso": "NG",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.43703,
        "lon": 3.377863,
        "speed": 0.1,
        "course": 339,
        "heading": null,
        "destination": "LEKKI",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1722217320,
        "eta_UTC": "2024-07-29T01:42:00Z",
        "distance": 5.24639477884575
    },
    {
        "uuid": "a7ba64f3-18fd-7cdb-e7c4-5a5e6369d0e6",
        "name": "DAURA",
        "mmsi": "657138200",
        "imo": "9769881",
        "eni": null,
        "country_iso": "NG",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.436372,
        "lon": 3.380973,
        "speed": 11.1,
        "course": 96,
        "heading": null,
        "destination": "LAGOS",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1715343180,
        "eta_UTC": "2024-05-10T12:13:00Z",
        "distance": 5.28635373877279
    },
    {
        "uuid": "53df0209-dc33-e73a-90b5-a0e82df56943",
        "name": "RIVER CHALAWA",
        "mmsi": "657131300",
        "imo": "9142382",
        "eni": null,
        "country_iso": "NG",
        "type": "Dredger",
        "type_specific": "Trailing Suction Hopper Dredger",
        "lat": 6.435917,
        "lon": 3.381617,
        "speed": 3.6,
        "course": 266,
        "heading": 266,
        "destination": "",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1707981240,
        "eta_UTC": "2024-02-15T07:14:00Z",
        "distance": 5.31457056963467
    },
    {
        "uuid": "b0bc90b1-78be-9921-8ee0-e2bea91ed419",
        "name": "RFA LYME BAY",
        "mmsi": "235051012",
        "imo": "9240768",
        "eni": null,
        "country_iso": "GB",
        "type": "Military Ops",
        "type_specific": "Combat Vessel",
        "lat": 6.435832,
        "lon": 3.375042,
        "speed": 0,
        "course": 226,
        "heading": 249,
        "destination": "",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1713322320,
        "eta_UTC": "2024-04-17T02:52:00Z",
        "distance": 5.32350001768713
    },
    {
        "uuid": "f7f23e0a-b181-712a-1db1-73cbd60c7519",
        "name": "DIAMANTGRACHT",
        "mmsi": "244142000",
        "imo": "9420813",
        "eni": null,
        "country_iso": "NL",
        "type": "Cargo",
        "type_specific": "General Cargo",
        "lat": 6.436082,
        "lon": 3.3949,
        "speed": 0,
        "course": 208,
        "heading": 201,
        "destination": "NGLOS",
        "last_position_epoch": 1730540100,
        "last_position_UTC": "2024-11-02T09:35:00Z",
        "eta_epoch": 1730305980,
        "eta_UTC": "2024-10-30T16:33:00Z",
        "distance": 5.3847902614779
    },
    {
        "uuid": "a71358d1-cb03-7b00-d765-a657c1332af1",
        "name": "ROSE III",
        "mmsi": "657220900",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Fishing",
        "type_specific": "Fishing",
        "lat": 6.43656,
        "lon": 3.397563,
        "speed": 0,
        "course": 43,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 5.38658094942706
    },
    {
        "uuid": "7e6a2899-1425-c68f-a72a-df34de1e9139",
        "name": "EXPRESS SPAIN",
        "mmsi": "248846000",
        "imo": "9443047",
        "eni": null,
        "country_iso": "MT",
        "type": "Cargo - Hazard B",
        "type_specific": "Container Ship",
        "lat": 6.435185,
        "lon": 3.38912,
        "speed": 0,
        "course": 38,
        "heading": 292,
        "destination": "BJCOO>>NGAPP",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730391720,
        "eta_UTC": "2024-10-31T16:22:00Z",
        "distance": 5.38915672539918
    },
    {
        "uuid": "782d82af-c0d5-0d47-e0f2-ce5ccda4dd9a",
        "name": "PC TOMBIA",
        "mmsi": "657221700",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Pilot Vessel",
        "type_specific": "Pilot Vessel",
        "lat": 6.434878,
        "lon": 3.393458,
        "speed": 0,
        "course": null,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 5.44188441139379
    },
    {
        "uuid": "da1630cd-c5b2-c6a9-3aa7-d4c5eee5c661",
        "name": "EAGLE",
        "mmsi": "671056100",
        "imo": "8030714",
        "eni": null,
        "country_iso": "TG",
        "type": "Other",
        "type_specific": "Offshore Supply Ship",
        "lat": 6.43468,
        "lon": 3.393108,
        "speed": 0,
        "course": 321,
        "heading": null,
        "destination": "LAGOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1714571400,
        "eta_UTC": "2024-05-01T13:50:00Z",
        "distance": 5.45040913260814
    },
    {
        "uuid": "51a48780-7402-c754-94f9-b3d2a65fc797",
        "name": "ALS CERES",
        "mmsi": "563186500",
        "imo": "9938303",
        "eni": null,
        "country_iso": "SG",
        "type": "Cargo",
        "type_specific": "Container Ship",
        "lat": 6.434192,
        "lon": 3.391693,
        "speed": 0,
        "course": 318,
        "heading": 292,
        "destination": "NGAPP",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730353320,
        "eta_UTC": "2024-10-31T05:42:00Z",
        "distance": 5.46722368435142
    },
    {
        "uuid": "88c196f9-c045-0dd8-d914-a0d6832e0e3a",
        "name": "NUE CURLEW",
        "mmsi": "657277100",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": null,
        "type_specific": "Unspecified",
        "lat": 6.433785,
        "lon": 3.364403,
        "speed": 0,
        "course": 198,
        "heading": 15,
        "destination": "LAGOS ANCHORAGE",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 5.5117708565224
    },
    {
        "uuid": "4e218082-c7e2-36b5-8c00-6106e26c83d0",
        "name": "SEA CHEASER",
        "mmsi": "657885000",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Passenger",
        "type_specific": "Passenger",
        "lat": 6.433733,
        "lon": 3.364142,
        "speed": 0,
        "course": 356,
        "heading": null,
        "destination": "UNKNOWN",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 5.51736649312756
    },
    {
        "uuid": "4cd31932-6f37-64d4-a5df-44c279ee7ed1",
        "name": "AUGUSTEO",
        "mmsi": "620687000",
        "imo": "7817141",
        "eni": null,
        "country_iso": "KM",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.432335,
        "lon": 3.37211,
        "speed": 4.8,
        "course": 248,
        "heading": null,
        "destination": "BONNY",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1711804020,
        "eta_UTC": "2024-03-30T13:07:00Z",
        "distance": 5.54384037984272
    },
    {
        "uuid": "dce54916-026e-1788-d77b-de86e19f109b",
        "name": "AVA J MCCALL",
        "mmsi": "657052872",
        "imo": "9802566",
        "eni": null,
        "country_iso": "NG",
        "type": "Anti-Pollution",
        "type_specific": "Offshore Supply Ship",
        "lat": 6.432035,
        "lon": 3.388142,
        "speed": 0,
        "course": 252,
        "heading": 270,
        "destination": "LAGOS LADOL",
        "last_position_epoch": 1730539980,
        "last_position_UTC": "2024-11-02T09:33:00Z",
        "eta_epoch": 1729604280,
        "eta_UTC": "2024-10-22T13:38:00Z",
        "distance": 5.57129211714276
    },
    {
        "uuid": "247bea51-1bb4-9491-2f68-886e42d41611",
        "name": "SAYONARA",
        "mmsi": "244670936",
        "imo": null,
        "eni": "2325534",
        "country_iso": "NL",
        "type": "Cargo",
        "type_specific": "Inland, Motor Freighter",
        "lat": 6.506488,
        "lon": 3.472128,
        "speed": 8.1,
        "course": 338,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 5.64681841342682
    },
    {
        "uuid": "e92326bc-6aef-0e7e-674a-046a43564db6",
        "name": "MISS LAILA",
        "mmsi": "511100886",
        "imo": "9175913",
        "eni": null,
        "country_iso": "PW",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.431555,
        "lon": 3.361287,
        "speed": 0,
        "course": 14,
        "heading": null,
        "destination": "LAGOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730464140,
        "eta_UTC": "2024-11-01T12:29:00Z",
        "distance": 5.67602048822874
    },
    {
        "uuid": "0d855bc6-1eca-fe65-519e-2deaa17c363b",
        "name": "KAMARI",
        "mmsi": "538007192",
        "imo": "9412634",
        "eni": null,
        "country_iso": "MH",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.43151,
        "lon": 3.359802,
        "speed": 0,
        "course": 199,
        "heading": 274,
        "destination": "NGLOS>>BRVIX",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1731585600,
        "eta_UTC": "2024-11-14T12:00:00Z",
        "distance": 5.69601038601803
    },
    {
        "uuid": "9d8a60ca-f46e-847a-0171-aec8d6a7f3e7",
        "name": "STAR MISTRAL",
        "mmsi": "636016288",
        "imo": "9684225",
        "eni": null,
        "country_iso": "LR",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.43171,
        "lon": 3.357785,
        "speed": 0,
        "course": 286,
        "heading": 274,
        "destination": "TIN CAN ISLAND",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730453460,
        "eta_UTC": "2024-11-01T09:31:00Z",
        "distance": 5.70996610653168
    },
    {
        "uuid": "87646a5d-3ea9-8844-51e3-2ec2aa507704",
        "name": "SPARROW",
        "mmsi": "636023074",
        "imo": "9960497",
        "eni": null,
        "country_iso": "LR",
        "type": "Cargo",
        "type_specific": "Container Ship",
        "lat": 6.43212,
        "lon": 3.351375,
        "speed": 0.1,
        "course": 55,
        "heading": 275,
        "destination": "NGTIN",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1730324400,
        "eta_UTC": "2024-10-30T21:40:00Z",
        "distance": 5.78391355165115
    },
    {
        "uuid": "984c79cb-7a3f-e06e-3b14-e57c4422ea1b",
        "name": "UBIMA",
        "mmsi": "657138100",
        "imo": "9769893",
        "eni": null,
        "country_iso": "NG",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.429522,
        "lon": 3.402863,
        "speed": 4.8,
        "course": 172,
        "heading": null,
        "destination": "LAGOS",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1729107480,
        "eta_UTC": "2024-10-16T19:38:00Z",
        "distance": 5.86888723336434
    },
    {
        "uuid": "eed1053e-a634-b5ac-4bd7-5a27c08e8780",
        "name": "APARNA",
        "mmsi": "657273700",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Pleasure Craft",
        "type_specific": "Pleasure Craft",
        "lat": 6.440418,
        "lon": 3.430073,
        "speed": 0.1,
        "course": null,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730539860,
        "last_position_UTC": "2024-11-02T09:31:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 5.8852656989171
    },
    {
        "uuid": "501393a4-3aaa-3a8c-b2c9-107e89094e4b",
        "name": "ELLIE M II",
        "mmsi": "538007447",
        "imo": "9307994",
        "eni": null,
        "country_iso": "MH",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.43689,
        "lon": 3.329608,
        "speed": 0,
        "course": 265,
        "heading": 93,
        "destination": "NG LOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1730427720,
        "eta_UTC": "2024-11-01T02:22:00Z",
        "distance": 6.02988030675144
    },
    {
        "uuid": "a526a467-8c26-768e-d919-dd74801eda6d",
        "name": "TIAN TAI SHAN",
        "mmsi": "636018023",
        "imo": "9737711",
        "eni": null,
        "country_iso": "LR",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.435488,
        "lon": 3.332173,
        "speed": 0,
        "course": 141,
        "heading": 38,
        "destination": "LAGOS",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730031540,
        "eta_UTC": "2024-10-27T12:19:00Z",
        "distance": 6.03068109973622
    },
    {
        "uuid": "557f9132-978a-c113-cd7a-c12b896ac79f",
        "name": "ZARANDA",
        "mmsi": "657011001",
        "imo": "9252723",
        "eni": null,
        "country_iso": "NG",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.426373,
        "lon": 3.403542,
        "speed": 6.7,
        "course": 185,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1722858720,
        "eta_UTC": "2024-08-05T11:52:00Z",
        "distance": 6.06215329363584
    },
    {
        "uuid": "b6a4f641-c518-bcc3-2327-2dc473d5fc48",
        "name": "COSMOS V",
        "mmsi": "657218800",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Fishing",
        "type_specific": "Fishing",
        "lat": 6.444107,
        "lon": 3.31685,
        "speed": 0,
        "course": 0,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730538840,
        "last_position_UTC": "2024-11-02T09:14:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 6.08903775926718
    },
    {
        "uuid": "14707586-a02e-0611-6dd0-f55d378f95da",
        "name": "ROSE I",
        "mmsi": "657220700",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Fishing",
        "type_specific": "Fishing",
        "lat": 6.44421,
        "lon": 3.316392,
        "speed": 0,
        "course": 0,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 6.1008849442817
    },
    {
        "uuid": "7a4a6cb2-db10-3463-f3ad-9f80932f92b8",
        "name": "STAR SHRIMPER XIX",
        "mmsi": "657216200",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Fishing",
        "type_specific": "Fishing",
        "lat": 6.443828,
        "lon": 3.316798,
        "speed": 0,
        "course": 0,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 6.10420100284645
    },
    {
        "uuid": "95ef0e79-a330-ec1e-2b44-5dcd40e57a6a",
        "name": "BESTAF 1",
        "mmsi": "657278400",
        "imo": "9382011",
        "eni": null,
        "country_iso": "NG",
        "type": "Cargo",
        "type_specific": "Deck Cargo Ship",
        "lat": 6.432235,
        "lon": 3.335132,
        "speed": null,
        "course": null,
        "heading": 309,
        "destination": "LAGOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1727730000,
        "eta_UTC": "2024-09-30T21:00:00Z",
        "distance": 6.12644159025766
    },
    {
        "uuid": "ac21f20d-933c-f70d-658f-560f10de2fd5",
        "name": "MUSTANG",
        "mmsi": "212854000",
        "imo": "9555383",
        "eni": null,
        "country_iso": "CY",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.425482,
        "lon": 3.407388,
        "speed": 0,
        "course": 304,
        "heading": 55,
        "destination": "NG LAG",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1729506000,
        "eta_UTC": "2024-10-21T10:20:00Z",
        "distance": 6.17262794623516
    },
    {
        "uuid": "d7a6d5a4-2692-56d9-763d-cdc696f31e46",
        "name": "SILVER SOUL",
        "mmsi": "370694000",
        "imo": "9153783",
        "eni": null,
        "country_iso": "PA",
        "type": "Cargo",
        "type_specific": "Vehicles Carrier",
        "lat": 6.429697,
        "lon": 3.338515,
        "speed": 0,
        "course": 11,
        "heading": 274,
        "destination": "US FPO>>NG LOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1727742480,
        "eta_UTC": "2024-10-01T00:28:00Z",
        "distance": 6.18244162751519
    },
    {
        "uuid": "c750cfb0-ad25-6688-3efe-986b337ec0ed",
        "name": "STARK",
        "mmsi": "577524000",
        "imo": "9537953",
        "eni": null,
        "country_iso": "VU",
        "type": "Other",
        "type_specific": "Offshore Supply Ship",
        "lat": 6.424736,
        "lon": 3.405683,
        "speed": 0,
        "course": 103,
        "heading": 191,
        "destination": "LAGOS PORT CONTROL",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": 1723993380,
        "eta_UTC": "2024-08-18T15:03:00Z",
        "distance": 6.18899222622951
    },
    {
        "uuid": "c21663de-b69c-665f-4376-a486781f29ad",
        "name": "DLB KENENNA",
        "mmsi": "577518000",
        "imo": "8354562",
        "eni": null,
        "country_iso": "VU",
        "type": "Special Craft",
        "type_specific": "Pipe Layer",
        "lat": 6.424548,
        "lon": 3.406792,
        "speed": 0,
        "course": 306,
        "heading": 54,
        "destination": "",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 6.21709569853486
    },
    {
        "uuid": "72329ce8-6ebc-e4cc-df30-8618f92a1305",
        "name": "AHTS GLORY",
        "mmsi": "657553100",
        "imo": "7416026",
        "eni": null,
        "country_iso": "NG",
        "type": "Other",
        "type_specific": "Tug",
        "lat": 6.425762,
        "lon": 3.3474,
        "speed": 0,
        "course": 0,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730539080,
        "last_position_UTC": "2024-11-02T09:18:00Z",
        "eta_epoch": 1729450020,
        "eta_UTC": "2024-10-20T18:47:00Z",
        "distance": 6.21876961424442
    },
    {
        "uuid": "ccf30ddf-6d39-68fd-1819-4227b666b4d9",
        "name": "MOSUNMOLA",
        "mmsi": "657246000",
        "imo": "9312456",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Crude Oil Tanker",
        "lat": 6.427062,
        "lon": 3.338777,
        "speed": null,
        "course": null,
        "heading": 276,
        "destination": "LAGOS",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1729097280,
        "eta_UTC": "2024-10-16T16:48:00Z",
        "distance": 6.32227645260702
    },
    {
        "uuid": "669064a0-648d-c28b-70f0-36f2736d079f",
        "name": "HD SCORPION",
        "mmsi": "657803000",
        "imo": "9561588",
        "eni": null,
        "country_iso": "NG",
        "type": "Dive Vessel",
        "type_specific": "Utility Vessel",
        "lat": 6.42615,
        "lon": 3.340033,
        "speed": 0,
        "course": 142,
        "heading": 56,
        "destination": "ANYALA/MADU FIELD",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1724220240,
        "eta_UTC": "2024-08-21T06:04:00Z",
        "distance": 6.34495392069814
    },
    {
        "uuid": "338ee8b8-1696-701b-6ffe-55c7d3b74ee4",
        "name": "LESTE",
        "mmsi": "657249200",
        "imo": "9285720",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.426095,
        "lon": 3.338928,
        "speed": 0,
        "course": 78,
        "heading": 243,
        "destination": "NG LOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730106180,
        "eta_UTC": "2024-10-28T09:03:00Z",
        "distance": 6.37258413747008
    },
    {
        "uuid": "25b10b3b-9dc9-d825-a3cd-e05bc62a6a69",
        "name": "HINTE",
        "mmsi": "244750408",
        "imo": null,
        "eni": null,
        "country_iso": "NL",
        "type": "Cargo",
        "type_specific": "Cargo",
        "lat": 6.4774,
        "lon": 3.480683,
        "speed": 0.1,
        "course": 11,
        "heading": 230,
        "destination": "LAGOS",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 6.67935899799351
    },
    {
        "uuid": "1f6c37af-e830-7225-cc33-3871d1e17ce2",
        "name": "BARBEL",
        "mmsi": "657863000",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.438162,
        "lon": 3.307733,
        "speed": 0,
        "course": 310,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 6.70727453729599
    },
    {
        "uuid": "8a2ebfc7-04ff-de65-eef7-c9daffb74c22",
        "name": "DESERT SPRING",
        "mmsi": "538004547",
        "imo": "9543768",
        "eni": null,
        "country_iso": "MH",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.409372,
        "lon": 3.399628,
        "speed": 5,
        "course": 4,
        "heading": 9,
        "destination": "NG LOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1730534400,
        "eta_UTC": "2024-11-02T08:00:00Z",
        "distance": 7.01310821178687
    },
    {
        "uuid": "aba9ac7a-d077-c642-e6d3-c38a34727e3c",
        "name": "NNS KANO",
        "mmsi": "375058000",
        "imo": "9804150",
        "eni": null,
        "country_iso": "VC",
        "type": "High Speed Craft",
        "type_specific": "Patrol Vessel",
        "lat": 6.441313,
        "lon": 3.286208,
        "speed": 0,
        "course": null,
        "heading": null,
        "destination": "LAGOS-NAVY",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730009220,
        "eta_UTC": "2024-10-27T06:07:00Z",
        "distance": 7.46074611569844
    },
    {
        "uuid": "adc5d8df-1730-9084-e88b-f1b23456be10",
        "name": "MONA 1",
        "mmsi": "657124200",
        "imo": "8679663",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.417005,
        "lon": 3.446757,
        "speed": 0,
        "course": 23,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 7.60407072103272
    },
    {
        "uuid": "174f7db8-dff5-8ffb-048d-2fe3215ca1cd",
        "name": "BREYDEL",
        "mmsi": "205520000",
        "imo": "9382384",
        "eni": null,
        "country_iso": "BE",
        "type": "Other",
        "type_specific": "Hopper Dredger",
        "lat": 6.416633,
        "lon": 3.44625,
        "speed": 0,
        "course": 280,
        "heading": 239,
        "destination": "NG LOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 7.60707212263989
    },
    {
        "uuid": "5a748945-1c5f-7a1d-5b08-fcf122847a17",
        "name": "DB LAGOS",
        "mmsi": "657231100",
        "imo": "9731303",
        "eni": null,
        "country_iso": "NG",
        "type": "High Speed Craft",
        "type_specific": "Offshore Supply Ship",
        "lat": 6.438677,
        "lon": 3.285138,
        "speed": 0,
        "course": null,
        "heading": 189,
        "destination": "NAVY TOWN",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1731430800,
        "eta_UTC": "2024-11-12T17:00:00Z",
        "distance": 7.61441731308521
    },
    {
        "uuid": "e66e4abd-fd2f-b21e-6b7d-690f92ff62c4",
        "name": "ALMA CONQUEST",
        "mmsi": "312579000",
        "imo": "8954702",
        "eni": null,
        "country_iso": "BZ",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.416553,
        "lon": 3.44692,
        "speed": 0,
        "course": 24,
        "heading": null,
        "destination": "EKO BAY NIGERIA",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1740549600,
        "eta_UTC": "2025-02-26T06:00:00Z",
        "distance": 7.63224129877741
    },
    {
        "uuid": "bc606c07-8968-0db0-09f3-08f57c07615a",
        "name": "PC KOKO",
        "mmsi": "657221600",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Pilot Vessel",
        "type_specific": "Pilot Vessel",
        "lat": 6.38819,
        "lon": 3.403673,
        "speed": 3.8,
        "course": 356,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 8.30750595831876
    },
    {
        "uuid": "7b9aea5c-18e8-2dec-aeb8-84dd4613e9b4",
        "name": "MSC GRETA III",
        "mmsi": "636021356",
        "imo": "9415296",
        "eni": null,
        "country_iso": "LR",
        "type": "Cargo - Hazard A (Major)",
        "type_specific": "Container Ship",
        "lat": 6.386358,
        "lon": 3.402968,
        "speed": 4.1,
        "course": 351,
        "heading": 349,
        "destination": "NG APP",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1730519760,
        "eta_UTC": "2024-11-02T03:56:00Z",
        "distance": 8.40861661121191
    },
    {
        "uuid": "2ff84bfb-68fc-c8fe-fe06-3ff28380af63",
        "name": "LAUSU",
        "mmsi": "538008073",
        "imo": null,
        "eni": null,
        "country_iso": "MH",
        "type": "Tanker",
        "type_specific": "Tanker",
        "lat": 6.429255,
        "lon": 3.267427,
        "speed": 0,
        "course": 49,
        "heading": 261,
        "destination": "LOME",
        "last_position_epoch": 1730538600,
        "last_position_UTC": "2024-11-02T09:10:00Z",
        "eta_epoch": 1727867940,
        "eta_UTC": "2024-10-02T11:19:00Z",
        "distance": 8.78054355799083
    },
    {
        "uuid": "948c9950-095c-3b2d-6ebc-9fe271ee8a54",
        "name": "SOCHIMA",
        "mmsi": "354618000",
        "imo": "9666376",
        "eni": null,
        "country_iso": "PA",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.383012,
        "lon": 3.334245,
        "speed": 0.1,
        "course": 173,
        "heading": 181,
        "destination": "LAGOS",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730300580,
        "eta_UTC": "2024-10-30T15:03:00Z",
        "distance": 8.90269304523482
    },
    {
        "uuid": "dcd52b9e-af41-05d9-ccf1-8bd74cb4da99",
        "name": "ALLAS",
        "mmsi": "657214100",
        "imo": "8521866",
        "eni": null,
        "country_iso": "NG",
        "type": "Cargo",
        "type_specific": "General Cargo",
        "lat": 6.3765,
        "lon": 3.39191,
        "speed": 0.2,
        "course": 250,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1726414980,
        "eta_UTC": "2024-09-15T15:43:00Z",
        "distance": 8.91239181297307
    },
    {
        "uuid": "f4319775-75b6-6840-5498-4eb927e704d0",
        "name": "TOKYO",
        "mmsi": "352004147",
        "imo": "9424649",
        "eni": null,
        "country_iso": "PA",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.428767,
        "lon": 3.26325,
        "speed": 0,
        "course": 287,
        "heading": 270,
        "destination": "NG LOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730384820,
        "eta_UTC": "2024-10-31T14:27:00Z",
        "distance": 8.98993787907666
    },
    {
        "uuid": "4e633d37-1aef-a06f-dfa7-0ad76db8995c",
        "name": "ORTAKOY 1",
        "mmsi": "657136300",
        "imo": "6919746",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Bunkering Tanker",
        "lat": 6.374188,
        "lon": 3.374528,
        "speed": 0.3,
        "course": null,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1728837240,
        "eta_UTC": "2024-10-13T16:34:00Z",
        "distance": 9.02319571783058
    },
    {
        "uuid": "7d467e70-7a0c-31ec-8600-e101c2ddff31",
        "name": "RISTNA",
        "mmsi": "657289700",
        "imo": "7915113",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Bunkering Tanker",
        "lat": 6.372714,
        "lon": 3.370715,
        "speed": 0.1,
        "course": 59,
        "heading": 134,
        "destination": "LAGOS NG",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1722786840,
        "eta_UTC": "2024-08-04T15:54:00Z",
        "distance": 9.12144814297451
    },
    {
        "uuid": "fd1a76d6-6b86-6ae4-1a68-7099d7fd7f7f",
        "name": "LADY KATINA",
        "mmsi": "657153300",
        "imo": "8558687",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Supply Vessel",
        "lat": 6.371543,
        "lon": 3.384768,
        "speed": 0,
        "course": 7,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1705763520,
        "eta_UTC": "2024-01-20T15:12:00Z",
        "distance": 9.18370811892089
    },
    {
        "uuid": "714d8b41-31d7-2c9e-d2dc-eb697544f01d",
        "name": "ROYAL",
        "mmsi": "622121120",
        "imo": null,
        "eni": null,
        "country_iso": "EG",
        "type": "Cargo",
        "type_specific": "Cargo",
        "lat": 6.370197,
        "lon": 3.381068,
        "speed": 0.2,
        "course": 293,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 9.25918410108661
    },
    {
        "uuid": "48212981-4f7b-ff83-b791-c85d491555f8",
        "name": "LUNA",
        "mmsi": "636023347",
        "imo": "9539810",
        "eni": null,
        "country_iso": "LR",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.36471,
        "lon": 3.388807,
        "speed": 0.3,
        "course": 349,
        "heading": 68,
        "destination": "LOS NG",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1730239800,
        "eta_UTC": "2024-10-29T22:10:00Z",
        "distance": 9.60507551814886
    },
    {
        "uuid": "0d916005-8ab4-68be-60e5-82629b2e7f77",
        "name": "VIRGO I",
        "mmsi": "351792000",
        "imo": "9176412",
        "eni": null,
        "country_iso": "PA",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.36984,
        "lon": 3.329778,
        "speed": 0.1,
        "course": 269,
        "heading": null,
        "destination": "OFFSHORE LAGOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1728673920,
        "eta_UTC": "2024-10-11T19:12:00Z",
        "distance": 9.73712369375164
    },
    {
        "uuid": "94587ef7-3c45-4555-14ec-46ba15e9a9d5",
        "name": "ISCARIA",
        "mmsi": "657282300",
        "imo": "7330052",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Chemical Tanker",
        "lat": 6.377698,
        "lon": 3.449542,
        "speed": 0.6,
        "course": 298,
        "heading": null,
        "destination": "LAGOS",
        "last_position_epoch": 1730534760,
        "last_position_UTC": "2024-11-02T08:06:00Z",
        "eta_epoch": 1718870100,
        "eta_UTC": "2024-06-20T07:55:00Z",
        "distance": 9.75681453666677
    },
    {
        "uuid": "3b56b4aa-3c1f-aa48-6b38-493b01730cae",
        "name": "FAIRCHEM VALOR",
        "mmsi": "563077600",
        "imo": "9791195",
        "eni": null,
        "country_iso": "SG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.365078,
        "lon": 3.341115,
        "speed": 0.1,
        "course": 55,
        "heading": 146,
        "destination": "NG LOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730471400,
        "eta_UTC": "2024-11-01T14:30:00Z",
        "distance": 9.83202414257387
    },
    {
        "uuid": "8530f14a-ab9d-2236-583f-ef7902a6a175",
        "name": "KEONAMEX VICTORY",
        "mmsi": "657243700",
        "imo": "9274642",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.36285,
        "lon": 3.351013,
        "speed": 0.1,
        "course": 84,
        "heading": 156,
        "destination": "LAGOS",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": 1722842640,
        "eta_UTC": "2024-08-05T07:24:00Z",
        "distance": 9.84433791694555
    },
    {
        "uuid": "a5711f65-f2c7-9679-b303-5cce17238ae2",
        "name": "ANANTA",
        "mmsi": "613711403",
        "imo": "8606331",
        "eni": null,
        "country_iso": "CM",
        "type": "Tanker - Hazard D (Recognizable)",
        "type_specific": "Inland Tanker",
        "lat": 6.438685,
        "lon": 3.234103,
        "speed": 0.1,
        "course": null,
        "heading": null,
        "destination": "LAG",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1718941500,
        "eta_UTC": "2024-06-21T03:45:00Z",
        "distance": 10.0704333127053
    },
    {
        "uuid": "ffd1b87c-95bb-4a74-4c2c-853b167e3dd0",
        "name": "MPG MATAGORDA",
        "mmsi": "657193000",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "SAR",
        "type_specific": "SAR",
        "lat": 6.44015,
        "lon": 3.230462,
        "speed": 0,
        "course": 211,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730538900,
        "last_position_UTC": "2024-11-02T09:15:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 10.2138815536502
    },
    {
        "uuid": "8c67002d-1df2-126c-f230-5b829c35cdd0",
        "name": "AYODEJI",
        "mmsi": "657273100",
        "imo": "9235701",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.353833,
        "lon": 3.377,
        "speed": 0.2,
        "course": 348,
        "heading": 78,
        "destination": "LAGOS",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": 1730409900,
        "eta_UTC": "2024-10-31T21:25:00Z",
        "distance": 10.2418665714354
    },
    {
        "uuid": "7a52f1b4-6556-f267-5ee1-283aab533d17",
        "name": "SANTA MONICA",
        "mmsi": "657993000",
        "imo": "9607318",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Crude Oil Tanker",
        "lat": 6.353957,
        "lon": 3.389247,
        "speed": 0,
        "course": 90,
        "heading": 43,
        "destination": "ARMED GUARDS ONBOARD",
        "last_position_epoch": 1730540100,
        "last_position_UTC": "2024-11-02T09:35:00Z",
        "eta_epoch": 1731560400,
        "eta_UTC": "2024-11-14T05:00:00Z",
        "distance": 10.2511209010445
    },
    {
        "uuid": "fde4e85c-5226-e809-8f29-95214277621b",
        "name": "DE CONQUEROR",
        "mmsi": "657143000",
        "imo": "8529674",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Supply Vessel",
        "lat": 6.353505,
        "lon": 3.377527,
        "speed": 0.4,
        "course": 203,
        "heading": null,
        "destination": "ARM",
        "last_position_epoch": 1730538660,
        "last_position_UTC": "2024-11-02T09:11:00Z",
        "eta_epoch": 1730547060,
        "eta_UTC": "2024-11-02T11:31:00Z",
        "distance": 10.2612044135589
    },
    {
        "uuid": "6e1b63cc-b1ff-f677-3976-b1367aac4c4c",
        "name": "BORA",
        "mmsi": "657249100",
        "imo": "9276004",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.356467,
        "lon": 3.337892,
        "speed": 0,
        "course": 251,
        "heading": 151,
        "destination": "ARMED GUARD ONBOARD",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1728492660,
        "eta_UTC": "2024-10-09T16:51:00Z",
        "distance": 10.3797056438892
    },
    {
        "uuid": "275fb103-2161-b95d-055c-e9c776a297b2",
        "name": "MONTAGU",
        "mmsi": "657252400",
        "imo": "9289764",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.435447,
        "lon": 3.22916,
        "speed": 0,
        "course": 259,
        "heading": 271,
        "destination": "ARMED GUARDS ONBOARD",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1725538860,
        "eta_UTC": "2024-09-05T12:21:00Z",
        "distance": 10.4232950391299
    },
    {
        "uuid": "99609027-8138-d219-b9ee-a30c5de21c0c",
        "name": "ORION STAR",
        "mmsi": "631254687",
        "imo": null,
        "eni": null,
        "country_iso": "GQ",
        "type": "Local Vessel",
        "type_specific": "Local Vessel",
        "lat": 6.360465,
        "lon": 3.315078,
        "speed": 0.4,
        "course": 276,
        "heading": null,
        "destination": "LAGOS ANCHORAGE",
        "last_position_epoch": 1730535900,
        "last_position_UTC": "2024-11-02T08:25:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 10.5601555569467
    },
    {
        "uuid": "0d97c7c0-ee12-22c5-2319-bc0e297325d8",
        "name": "MAESTRO",
        "mmsi": "657249900",
        "imo": "9173111",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.36093,
        "lon": 3.313868,
        "speed": 0.5,
        "course": 192,
        "heading": 169,
        "destination": "ARMED GUARD ONBOARD",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1729331820,
        "eta_UTC": "2024-10-19T09:57:00Z",
        "distance": 10.5605686043476
    },
    {
        "uuid": "f9f6b501-868a-b5fe-be72-e1d7d6ce770f",
        "name": "COROMEL",
        "mmsi": "636019275",
        "imo": "9341378",
        "eni": null,
        "country_iso": "LR",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.347136,
        "lon": 3.357175,
        "speed": 0,
        "course": 41,
        "heading": 134,
        "destination": "LAG0S",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730022180,
        "eta_UTC": "2024-10-27T09:43:00Z",
        "distance": 10.7239356326888
    },
    {
        "uuid": "3dc856b4-0844-bd83-f483-fe305b47f218",
        "name": "FOURTHMAN 1",
        "mmsi": "341506000",
        "imo": "9181508",
        "eni": null,
        "country_iso": "KN",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.346643,
        "lon": 3.34234,
        "speed": 0,
        "course": 55,
        "heading": 121,
        "destination": "NAVY ON BOARD",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1726552140,
        "eta_UTC": "2024-09-17T05:49:00Z",
        "distance": 10.8969401842153
    },
    {
        "uuid": "90c51f26-e230-00f8-fd78-9d4bb241a14f",
        "name": "STAR TAISHAN",
        "mmsi": "352004430",
        "imo": "9519200",
        "eni": null,
        "country_iso": "PA",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.349602,
        "lon": 3.325605,
        "speed": 0.3,
        "course": 318,
        "heading": 132,
        "destination": "ARMGUARD ONBOARD",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": 1730282580,
        "eta_UTC": "2024-10-30T10:03:00Z",
        "distance": 10.971371830946
    },
    {
        "uuid": "d38eeca9-bf80-d0a3-783e-6fab832d16e3",
        "name": "AQUARIUS",
        "mmsi": "636018924",
        "imo": "9354272",
        "eni": null,
        "country_iso": "LR",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.34176,
        "lon": 3.348813,
        "speed": 0.5,
        "course": 13,
        "heading": 123,
        "destination": "OFFSHORE LAGOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1729055880,
        "eta_UTC": "2024-10-16T05:18:00Z",
        "distance": 11.1147598087172
    },
    {
        "uuid": "a832c039-78a7-e442-1291-997f203264d6",
        "name": "BARWASA",
        "mmsi": "657159800",
        "imo": "9082221",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.343593,
        "lon": 3.336323,
        "speed": 0.2,
        "course": 331,
        "heading": 176,
        "destination": "LAGOS OFFSHORE",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1723971420,
        "eta_UTC": "2024-08-18T08:57:00Z",
        "distance": 11.1531880448708
    },
    {
        "uuid": "98723d21-6738-870d-79ac-1ccfc7fd003a",
        "name": "LIBRA II",
        "mmsi": "538010988",
        "imo": "9858773",
        "eni": null,
        "country_iso": "MH",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.340035,
        "lon": 3.403415,
        "speed": 0.1,
        "course": 127,
        "heading": 28,
        "destination": "LAGOS OFFSHORE",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1729729500,
        "eta_UTC": "2024-10-24T00:25:00Z",
        "distance": 11.163354905911
    },
    {
        "uuid": "54422920-058c-e5dd-b31f-c353dda9c849",
        "name": "ZONDA",
        "mmsi": "657248400",
        "imo": "9285732",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.350945,
        "lon": 3.30987,
        "speed": 0,
        "course": 34,
        "heading": 134,
        "destination": "ARMED GUARDS ONBOARD",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1727577900,
        "eta_UTC": "2024-09-29T02:45:00Z",
        "distance": 11.2058028042282
    },
    {
        "uuid": "2e16c062-234b-ed83-9d20-fd9465cae514",
        "name": "AFRICAN MARVEL",
        "mmsi": "353116000",
        "imo": "9856658",
        "eni": null,
        "country_iso": "PA",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.356888,
        "lon": 3.286793,
        "speed": 0.1,
        "course": 178,
        "heading": 132,
        "destination": "ARMED GUARDS ONBOARD",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1730026200,
        "eta_UTC": "2024-10-27T10:50:00Z",
        "distance": 11.4695531796863
    },
    {
        "uuid": "d7601adb-14bd-8da7-b7d6-7bb449a2a0de",
        "name": "BEDFORD",
        "mmsi": "636022019",
        "imo": "9808467",
        "eni": null,
        "country_iso": "LR",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.333004,
        "lon": 3.358475,
        "speed": 0.1,
        "course": 217,
        "heading": 115,
        "destination": "LAGOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1727137800,
        "eta_UTC": "2024-09-24T00:30:00Z",
        "distance": 11.5579560715936
    },
    {
        "uuid": "f202030f-9fe3-adc1-d5ab-5b5684faa5a9",
        "name": "ANDREA 1",
        "mmsi": "657172700",
        "imo": "9855006",
        "eni": null,
        "country_iso": "NG",
        "type": "Law Enforce",
        "type_specific": "Crew Boat",
        "lat": 6.333795,
        "lon": 3.415517,
        "speed": 1.5,
        "course": 39,
        "heading": null,
        "destination": "FLT ONNE PORT",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1727900580,
        "eta_UTC": "2024-10-02T20:23:00Z",
        "distance": 11.6474498659079
    },
    {
        "uuid": "773c205c-7f69-006d-8d43-9cb1e935e470",
        "name": "PERMIAN LADY",
        "mmsi": "566506000",
        "imo": "9729269",
        "eni": null,
        "country_iso": "SG",
        "type": "Tanker - Hazard D (Recognizable)",
        "type_specific": "LPG Tanker",
        "lat": 6.332231666666667,
        "lon": 3.41008,
        "speed": 4.5,
        "course": 48.5,
        "heading": 39,
        "destination": "LOME FOR ORDERS",
        "last_position_epoch": 1730540833,
        "last_position_UTC": "2024-11-02T09:47:13Z",
        "eta_epoch": 1730534400,
        "eta_UTC": "2024-11-02T08:00:00Z",
        "distance": 11.6841666562554
    },
    {
        "uuid": "ba695060-f848-c504-6b12-3599ac19a828",
        "name": "SHAMAL",
        "mmsi": "636023064",
        "imo": "9391476",
        "eni": null,
        "country_iso": "LR",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.346852,
        "lon": 3.298163,
        "speed": 0.1,
        "course": 34,
        "heading": 137,
        "destination": "ARMED GUARDS ONBOARD",
        "last_position_epoch": 1730540160,
        "last_position_UTC": "2024-11-02T09:36:00Z",
        "eta_epoch": 1730530860,
        "eta_UTC": "2024-11-02T07:01:00Z",
        "distance": 11.7053573262661
    },
    {
        "uuid": "b3ccf09f-df3e-d82e-a0b2-034ecf09010b",
        "name": "CHAMPION",
        "mmsi": "657816000",
        "imo": "9072654",
        "eni": null,
        "country_iso": "NG",
        "type": "Cargo",
        "type_specific": "Oil Products Tanker",
        "lat": 6.346467,
        "lon": 3.29809,
        "speed": 0.3,
        "course": 234,
        "heading": null,
        "destination": "LAGOS",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1730450220,
        "eta_UTC": "2024-11-01T08:37:00Z",
        "distance": 11.7282101655869
    },
    {
        "uuid": "8880a3c3-125a-4086-b4c1-18fe032613cb",
        "name": "ST ILHAAM",
        "mmsi": "657162200",
        "imo": "9278480",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.328691666666667,
        "lon": 3.383005,
        "speed": 0.8,
        "course": 193.2,
        "heading": 100,
        "destination": "ARM GUARDS ONBOARD",
        "last_position_epoch": 1730540849,
        "last_position_UTC": "2024-11-02T09:47:29Z",
        "eta_epoch": 1733810400,
        "eta_UTC": "2024-12-10T06:00:00Z",
        "distance": 11.7527308701284
    },
    {
        "uuid": "07f23160-62e3-e9ee-3b50-7dfafebee33d",
        "name": "YU YI",
        "mmsi": "477300900",
        "imo": "9675925",
        "eni": null,
        "country_iso": "HK",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.327202,
        "lon": 3.395388,
        "speed": 0.8,
        "course": 185,
        "heading": 97,
        "destination": "OFFSHORE LAGOS",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1728513360,
        "eta_UTC": "2024-10-09T22:36:00Z",
        "distance": 11.8793077177225
    },
    {
        "uuid": "40d2cbdb-fd49-bb00-f3a5-ee2882951b75",
        "name": "KASTOS I",
        "mmsi": "352004029",
        "imo": "9282297",
        "eni": null,
        "country_iso": "PA",
        "type": "Tanker",
        "type_specific": "Crude Oil Tanker",
        "lat": 6.320533,
        "lon": 3.364717,
        "speed": 0.1,
        "course": 200,
        "heading": 97,
        "destination": "NGLOS",
        "last_position_epoch": 1730540100,
        "last_position_UTC": "2024-11-02T09:35:00Z",
        "eta_epoch": 1729834680,
        "eta_UTC": "2024-10-25T05:38:00Z",
        "distance": 12.2708566346853
    },
    {
        "uuid": "f737947a-b4cc-14f6-ef17-5297bf2360de",
        "name": "SABAEK",
        "mmsi": "352898728",
        "imo": "9240433",
        "eni": null,
        "country_iso": "PA",
        "type": "Cargo",
        "type_specific": "Obo Carrier",
        "lat": 6.320213,
        "lon": 3.402267,
        "speed": 0,
        "course": 353,
        "heading": 88,
        "destination": "NG LOS",
        "last_position_epoch": 1730540220,
        "last_position_UTC": "2024-11-02T09:37:00Z",
        "eta_epoch": 1730039940,
        "eta_UTC": "2024-10-27T14:39:00Z",
        "distance": 12.3366154085651
    },
    {
        "uuid": "f9ead7a0-f18d-ab4d-abf0-049d4a3bbe9e",
        "name": "CENTRAL",
        "mmsi": "538007544",
        "imo": "9383895",
        "eni": null,
        "country_iso": "MH",
        "type": "Tanker",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.318682,
        "lon": 3.382302,
        "speed": 0.7,
        "course": 100,
        "heading": 100,
        "destination": "NGESC",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730230500,
        "eta_UTC": "2024-10-29T19:35:00Z",
        "distance": 12.3529157253869
    },
    {
        "uuid": "07a34698-993c-3396-afeb-670457d707b0",
        "name": "BINTA SALEH",
        "mmsi": "538008289",
        "imo": "9402809",
        "eni": null,
        "country_iso": "MH",
        "type": "Tanker - Hazard A (Major)",
        "type_specific": "Oil Products Tanker",
        "lat": 6.318073,
        "lon": 3.351083,
        "speed": 0.5,
        "course": 189,
        "heading": 97,
        "destination": "LAGOS",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1730488740,
        "eta_UTC": "2024-11-01T19:19:00Z",
        "distance": 12.5011664524608
    },
    {
        "uuid": "bdd69d6f-7079-cac9-2738-3abcc3ab30f9",
        "name": "STAR SANDPIPER",
        "mmsi": "636024233",
        "imo": "9441427",
        "eni": null,
        "country_iso": "LR",
        "type": "Cargo",
        "type_specific": "Bulk Carrier",
        "lat": 6.3359,
        "lon": 3.289023,
        "speed": 0.2,
        "course": 331,
        "heading": 110,
        "destination": "NGLOS",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730280240,
        "eta_UTC": "2024-10-30T09:24:00Z",
        "distance": 12.531499724869
    },
    {
        "uuid": "d3e3d1a7-b1be-85c0-7429-ebc97c36f74d",
        "name": "NUE FERULA",
        "mmsi": "657245900",
        "imo": "8959556",
        "eni": null,
        "country_iso": "NG",
        "type": "Other",
        "type_specific": "Offshore Supply Ship",
        "lat": 6.354855,
        "lon": 3.25181,
        "speed": 0.9,
        "course": 325,
        "heading": 279,
        "destination": "MILLITARY-ACTIVITY",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1727590620,
        "eta_UTC": "2024-09-29T06:17:00Z",
        "distance": 12.7039830509368
    },
    {
        "uuid": "71ed09d9-a285-e6a2-f709-c2d823786dca",
        "name": "KINGIS",
        "mmsi": "657128900",
        "imo": "9210892",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker - Hazard B",
        "type_specific": "Oil or Chemical Tanker",
        "lat": 6.30824,
        "lon": 3.354625,
        "speed": 0.3,
        "course": 84,
        "heading": null,
        "destination": "ARMGUARDS ON BOARD",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730223360,
        "eta_UTC": "2024-10-29T17:36:00Z",
        "distance": 13.0610423865112
    },
    {
        "uuid": "63ab870a-0237-ded7-7ca1-bbba118c358e",
        "name": "ST WALGA",
        "mmsi": "657191200",
        "imo": "9286061",
        "eni": null,
        "country_iso": "NG",
        "type": "Tanker",
        "type_specific": "Oil Products Tanker",
        "lat": 6.305151,
        "lon": 3.392008,
        "speed": 0.1,
        "course": 192,
        "heading": 98,
        "destination": "LAGOS OFF",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": 1730445780,
        "eta_UTC": "2024-11-01T07:23:00Z",
        "distance": 13.1861075861905
    },
    {
        "uuid": "886a03d7-6100-f90b-7db1-c63944c8f3cd",
        "name": "STEPHANIE III",
        "mmsi": "852446600",
        "imo": null,
        "eni": null,
        "country_iso": null,
        "type": "Fishing",
        "type_specific": "Fishing",
        "lat": 6.303615,
        "lon": 3.34536,
        "speed": 2.6,
        "course": 184,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730537520,
        "last_position_UTC": "2024-11-02T08:52:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 13.4090505622438
    },
    {
        "uuid": "84268056-c1a1-410e-5ebd-8e81a88b6251",
        "name": "INNOVATION WAY",
        "mmsi": "356485000",
        "imo": "9221205",
        "eni": null,
        "country_iso": "PA",
        "type": "Other",
        "type_specific": "Heavy Load Carrier",
        "lat": 6.341989,
        "lon": 3.231817,
        "speed": 0,
        "course": 230,
        "heading": 110,
        "destination": "LAGOS",
        "last_position_epoch": 1730540280,
        "last_position_UTC": "2024-11-02T09:38:00Z",
        "eta_epoch": 1730176680,
        "eta_UTC": "2024-10-29T04:38:00Z",
        "distance": 14.0453517739943
    },
    {
        "uuid": "a019eaa8-0d5e-1d15-e17e-f638a5475990",
        "name": "GREENBOOT",
        "mmsi": "657238700",
        "imo": "9902055",
        "eni": null,
        "country_iso": "NG",
        "type": "Passenger",
        "type_specific": "Crew Boat",
        "lat": 6.334175,
        "lon": 3.599625,
        "speed": 9.3,
        "course": 80,
        "heading": 86,
        "destination": "USING ENGINE",
        "last_position_epoch": 1730540460,
        "last_position_UTC": "2024-11-02T09:41:00Z",
        "eta_epoch": 1730530500,
        "eta_UTC": "2024-11-02T06:55:00Z",
        "distance": 17.4184787010861
    },
    {
        "uuid": "1005d0da-03b2-0015-d8fa-ec196de1b520",
        "name": "SCREAMING REELS",
        "mmsi": "657212500",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Pleasure Craft",
        "type_specific": "Pleasure Craft",
        "lat": 6.236658,
        "lon": 3.442448,
        "speed": 6.2,
        "course": 151,
        "heading": 141,
        "destination": "CLASS B",
        "last_position_epoch": 1730535900,
        "last_position_UTC": "2024-11-02T08:25:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 17.6837372137993
    },
    {
        "uuid": "80a9e60e-4ed6-78b1-1fc8-6a72aecfd00d",
        "name": "SIS ANSEL",
        "mmsi": "657211700",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Sailing Vessel",
        "type_specific": "Sailing Vessel",
        "lat": 6.231352,
        "lon": 3.255218,
        "speed": 11.4,
        "course": 174,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540160,
        "last_position_UTC": "2024-11-02T09:36:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 19.0869186629295
    },
    {
        "uuid": "503c05c3-7de6-9be8-690b-96754341962f",
        "name": "GANNET",
        "mmsi": "657164900",
        "imo": "9412141",
        "eni": null,
        "country_iso": "NG",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.31362,
        "lon": 3.643028,
        "speed": 6.9,
        "course": 95,
        "heading": 85,
        "destination": "LAGOS",
        "last_position_epoch": 1730540340,
        "last_position_UTC": "2024-11-02T09:39:00Z",
        "eta_epoch": 1730316360,
        "eta_UTC": "2024-10-30T19:26:00Z",
        "distance": 20.1976727018984
    },
    {
        "uuid": "511f5478-c028-cd99-14cf-2cf24b6d0f72",
        "name": "NNS ABA",
        "mmsi": "657764000",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Other",
        "type_specific": "Other",
        "lat": 6.278202,
        "lon": 3.614388,
        "speed": 1.6,
        "course": 261,
        "heading": null,
        "destination": "",
        "last_position_epoch": 1730540400,
        "last_position_UTC": "2024-11-02T09:40:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 20.3820821207887
    },
    {
        "uuid": "59df5a0d-713e-09bb-741a-5e6b40f7abbf",
        "name": "MAC II",
        "mmsi": "657212600",
        "imo": null,
        "eni": null,
        "country_iso": "NG",
        "type": "Pleasure Craft",
        "type_specific": "Pleasure Craft",
        "lat": 6.180472,
        "lon": 3.457645,
        "speed": 2.4,
        "course": 329,
        "heading": null,
        "destination": "CLASS B",
        "last_position_epoch": 1730538780,
        "last_position_UTC": "2024-11-02T09:13:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 21.1737148258237
    },
    {
        "uuid": "b157a273-0957-0394-f0b0-5824dfac4dd8",
        "name": "NV ATHENA",
        "mmsi": "563198700",
        "imo": "9527075",
        "eni": null,
        "country_iso": "SG",
        "type": "Tanker - Hazard B",
        "type_specific": "Chemical Tanker",
        "lat": 6.18415,
        "lon": 3.2544516666666667,
        "speed": 11.8,
        "course": 181.1,
        "heading": 176,
        "destination": "NG LOS",
        "last_position_epoch": 1730540846,
        "last_position_UTC": "2024-11-02T09:47:26Z",
        "eta_epoch": 1731659400,
        "eta_UTC": "2024-11-15T08:30:00Z",
        "distance": 21.7429553208417
    },
    {
        "uuid": "ebdae545-f920-cd3a-75e5-9b42cdbbb4a3",
        "name": "MAERSK COLOMBO",
        "mmsi": "566492000",
        "imo": "9525390",
        "eni": null,
        "country_iso": "SG",
        "type": "Cargo",
        "type_specific": "Container Ship",
        "lat": 6.0738916666666665,
        "lon": 3.344035,
        "speed": 16.4,
        "course": 197.1,
        "heading": 195,
        "destination": "IN NSA>CG PNR",
        "last_position_epoch": 1730540754,
        "last_position_UTC": "2024-11-02T09:45:54Z",
        "eta_epoch": 1730161680,
        "eta_UTC": "2024-10-29T00:28:00Z",
        "distance": 27.1302903666225
    },
    {
        "uuid": "efa662b1-01ba-a0cd-c1ec-699f6375d613",
        "name": "WAPCO3",
        "mmsi": "610000007",
        "imo": "9977464",
        "eni": null,
        "country_iso": "BJ",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.369843,
        "lon": 2.68096,
        "speed": 0.4,
        "course": 341,
        "heading": 129,
        "destination": "COTONOU",
        "last_position_epoch": 1730536680,
        "last_position_UTC": "2024-11-02T08:38:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 42.6790080768786
    },
    {
        "uuid": "d7b3282a-b817-0349-d287-c8daf9f76a77",
        "name": "WAPCO2",
        "mmsi": "610000006",
        "imo": "9977452",
        "eni": null,
        "country_iso": "BJ",
        "type": "Tug",
        "type_specific": "Tug",
        "lat": 6.369747,
        "lon": 2.67938,
        "speed": 3.5,
        "course": 266,
        "heading": null,
        "destination": "SEME",
        "last_position_epoch": 1730538300,
        "last_position_UTC": "2024-11-02T09:05:00Z",
        "eta_epoch": null,
        "eta_UTC": null,
        "distance": 42.7722774384105
    }
]