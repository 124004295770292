import React from "react";
import Title from "../../components/text/Title";
import containerTrackingImage from "../../assets/images/container-tracking.png";

export default function ContainerTrackingPage() {

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 0 }}>
                <Title>Container Tracking</Title>
            </div>
            <div style={{backgroundColor: "#689087", textAlign: "center"}}>
                <img src={containerTrackingImage} width="100%" />
            </div>
        </>
    );
}