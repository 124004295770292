import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/system";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Toolbar,
  AppBar,
  IconButton,
  Badge,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/actions/menuAction";
import useIsMobile from "../../utils/useIsMobile";
import { AccountCircle } from "@mui/icons-material";

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  height: 75px;
`;

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    color: #1d8a50 !important;
`;

const AccordionMenuItem = styled("div")`
  width: 100%;
  cursor: pointer;
  border-radius: 0.5rem;
  padding-bottom: 4px;
  padding-left: 12px;
  &:hover {
    background: #f3f5f9;
  }
`;

const Header = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const accordionRef = useRef();
  const sidebarCollapsed = useSelector((store) => store.menu.sidebarCollapsed);
  const profile = useSelector((store) => store.user.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    // Define the outside click handler
    const handleClickOutside = (event) => {
      if (
        accordionRef.current &&
        !accordionRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Attach the handler for click event
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("type");
    navigate("/auth/login");
  };

  const isMobile = useIsMobile();

  return (
    <HeaderWrapper zIndex={location.pathname === "/tracking" ? 9999 : 999}>
      <AppBar position="static" color="">
        <StyledToolbar>
          <StyledIconButton>
            {isMobile &&
              <SidebarCloseBtn onClick={() => dispatch(toggleSidebar(!sidebarCollapsed))}>
                <MenuIcon color="#ddd" />
              </SidebarCloseBtn>
            }
          </StyledIconButton>
          <div style={{height: '75px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: 10, justifyContent: "center", alignItems: "center"}}>
              {/* <Link to="#">
                <IconButton aria-label="show new notifications" color="inherit" style={{height: '20px'}}>
                  <Badge badgeContent={0} color="error">
                    <NotificationsActiveIcon style={{color: "#1D8A50"}} />
                  </Badge>
                </IconButton>
              </Link> */}
              <AccountCircle color="#00A758" style={{color: "#00A758"}} />
              <Link to="#" className="link">
                <Typography style={{lineHeight: '0px'}}>{profile?.name}</Typography>
              </Link>
            </div>
          </div>
        </StyledToolbar>
      </AppBar>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled("div")(({ zIndex }) => ({
  position: "sticky",
  width: "100%",
  backgroundColor: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "flex-start",
  top: 0,
  zIndex: zIndex,
}));

const SidebarCloseBtn = styled(Box)`
  position: relative;
  right: 0px;
  border: 0px solid #ebebeb;
  top: 0px;
  width: 37px;
  height: 31px;
  border-radius: 0px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
  &:hover {
    
  }
  > img {
    width: 6px;
    height: 11px;
  }
`;

export default Header;
