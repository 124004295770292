import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import PaystackPop from '@paystack/inline-js'
import ReusableTable from "../../components/Table/CustomTable";
import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import SelectInput from "../../components/input/SelectInput";
import CustomInput from "../../components/input/CustomInput";
import CustomModal from "../../components/modal/CustomModal";
import { useSelector } from "react-redux";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { Checkbox } from '@mui/material';

export default function TariffPage() {
    const profile = useSelector((store) => store.user.profile);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openPayModal, setOpenPayModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [isChecked, setIsChecked] = useState(true);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [currency, setCurrency] = useState("");
    const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
    const [tariffAmount, setTariffAmount] = useState("");

    const [nameEdit, setNameEdit] = useState("");
    const [typeEdit, setTypeEdit] = useState("");
    const [currencyEdit, setCurrencyEdit] = useState("");
    const [unitOfMeasurementEdit, setUnitOfMeasurementEdit] = useState("");
    const [tariffAmountEdit, setTariffAmountEdit] = useState("");
    
    const tableAction = (row) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5}}>
                <button 
                    style={{backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0}} 
                    onClick={() => {
                        setCurrentItem(row);
                        setSelectedPaymentObject(row);
                        setOpenPayModal(true);
                    }}
                    >
                    Pay
                </button>
                {profile?.userType === "admin" &&
                    <button 
                        style={{backgroundColor: "#00A758", color: "#fff", cursor: "pointer", padding: "5px 10px", borderRadius: 2, border: 0}} 
                        onClick={() => {
                            setNameEdit(row.name);
                            setTypeEdit(row.type);
                            setCurrencyEdit(row.currency);
                            setTariffAmountEdit(row.amount);
                            setUnitOfMeasurementEdit(row.unitOfMeasurement);
                            setCurrentItem(row);
                            setOpenEditModal(true);
                        }}
                        >
                        Edit
                    </button>
                }
            </div>
        );
    };

    const [selectedPayment, setSelectedPayment] = useState('');
    const [selectedPaymentObject, setSelectedPaymentObject] = useState('');
    const [portName, setPortName] = useState('Apapa');
    
    const handleSelectPayment = (itemId) => {
        setSelectedPayment(itemId);
        const selected = data.filter(item => item.name === itemId);
        const selectedObj = selected[0];
        setSelectedPaymentObject(selectedObj);
    }

    useEffect(() => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/tariff/all`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.code === 200) {
                    setData(result?.data);
                } else {

                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setCustomerName(profile?.name);
        setCustomerEmail(profile?.email);
    }, [profile]);

    const handleAdd = () => {
        if (!name || !type || !currency || !unitOfMeasurement || !tariffAmount) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                name, type, currency, unitOfMeasurement, amount: tariffAmount,
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/tariff/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const handleEdit = () => {
        if (!nameEdit || !typeEdit || !currencyEdit || !unitOfMeasurementEdit || !tariffAmountEdit) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                id: currentItem?._id,
                name: nameEdit, 
                type: typeEdit, 
                currency: currencyEdit, 
                unitOfMeasurement: unitOfMeasurementEdit, 
                amount: tariffAmountEdit,
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/tariff/edit`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    const [customerName, setCustomerName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [itemQuantity, setItemQuantity] = useState("");

    const handlePay = async () => {
        if (!customerName || !customerEmail || !customerPhone || !itemQuantity || !selectedPaymentObject || !portName) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                customerName, customerEmail, customerPhone, itemQuantity, itemId: selectedPaymentObject?._id, portName, isChecked
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/payment/generate-access-code`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result?.code === 200) {
                    const accessCode = result?.data?.data?.access_code;
                    const popup = new PaystackPop();
                    setOpenPayModal(false);
                    popup.resumeTransaction(accessCode);
                } else {
                    setAlertMessage(result?.message);
                    setAlertType("error");
                    setAlertOpen(true);
                }
                setButtonLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setAlertMessage("An error occured, try again !");
                setAlertType("error");
                setAlertOpen(true);
                setButtonLoading(false);
            });
        }
    }

    return(
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />
            <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Add Tariff
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput 
                    label="Tariff Name"
                    placeholder="Enter the Tariff"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <SelectInput
                    data={[
                        {id: 1, name: "rate"},
                        {id: 2, name: "fixed"},
                        {id: 3, name: "percentage"}
                    ]}
                    label="Select Type"
                    value={selectedPayment}
                    onChange={(e) => {
                        setType(e.target.value);
                    }}
                />
                <CustomInput 
                    label="Amount"
                    placeholder="Enter Amount or Rate"
                    type="number"
                    value={tariffAmount}
                    onChange={(e) => setTariffAmount(e.target.value)}
                />
                <SelectInput
                    data={[
                        {id: 1, name: "USD"},
                        {id: 2, name: "NGN"}
                    ]}
                    label="Select Currency"
                    value={selectedPayment}
                    onChange={(e) => {
                        setCurrency(e.target.value);
                    }}
                />
                <CustomInput 
                    label="Unit of Measurement"
                    placeholder="Enter the Unit of Measurement"
                    type="text"
                    value={unitOfMeasurement}
                    onChange={(e) => setUnitOfMeasurement(e.target.value)}
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAdd()}>
                    Save
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openEditModal} setOpenModal={setOpenEditModal}>
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Edit Tariff
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenEditModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput 
                    label="Tariff Name"
                    placeholder="Enter the Tariff"
                    value={nameEdit}
                    onChange={(e) => setNameEdit(e.target.value)}
                />
                <SelectInput
                    data={[
                        {id: 1, name: "rate"},
                        {id: 2, name: "fixed"},
                        {id: 3, name: "percentage"}
                    ]}
                    label="Select Type"
                    value={typeEdit}
                    onChange={(e) => {
                        setTypeEdit(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomInput 
                    label="Amount"
                    placeholder="Enter Amount or Rate"
                    type="number"
                    value={tariffAmountEdit}
                    onChange={(e) => setTariffAmountEdit(e.target.value)}
                />
                <SelectInput
                    data={[
                        {id: 1, name: "USD"},
                        {id: 2, name: "NGN"}
                    ]}
                    label="Select Currency"
                    value={currencyEdit}
                    onChange={(e) => {
                        setCurrencyEdit(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomInput 
                    label="Unit of Measurement"
                    placeholder="Enter the Unit of Measurement"
                    type="text"
                    value={unitOfMeasurementEdit}
                    onChange={(e) => setUnitOfMeasurementEdit(e.target.value)}
                />
                <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleEdit()}>
                    Update
                </CustomButton>
            </CustomModal>
            <CustomModal openModal={openPayModal} setOpenModal={setOpenPayModal}>
                <div
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: "20px" }}>
                        Make Payment
                    </Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => setOpenPayModal(false)}>
                        <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
                    </div>
                </div>
                <CustomInput 
                    label="Customer Name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                />
                <CustomInput 
                    label="Customer Email"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                />
                <CustomInput
                    label="Customer Phone"
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                />
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, paddingBottom: 10,}}>
                    <Checkbox
                        checked={isChecked}
                        onChange={(event) => {
                            setIsChecked(event.target.checked);
                        }}
                        name="agreed"
                        color="success"
                        sx={{ color: '#00A758' }}
                    /> Allow customer to receipt email/SMS notification
                </div>
                <SelectInput
                    data={[
                        {id: 1, name: "Apapa"},
                        {id: 2, name: "Tincan"},
                        {id: 3, name: "Lekki"},
                        {id: 4, name: "Calabar"},
                        {id: 5, name: "Warri"},
                        {id: 6, name: "Onne"},
                        {id: 7, name: "Rivers"}
                    ]}
                    label="Select Port"
                    value={portName}
                    onChange={(e) => {
                        setPortName(e.target.value);
                    }}
                    placeholder="Select Port"
                />
                <SelectInput
                    data={data}
                    label="Select Payment"
                    value={selectedPayment}
                    defaultValue={currentItem?.name}
                    onChange={(e) => {
                        handleSelectPayment(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomInput 
                    label={`Quantity (${selectedPaymentObject?.unitOfMeasurement})`}
                    value={itemQuantity}
                    onChange={(e) => setItemQuantity(e.target.value)}
                />
                <div style={{marginBottom: 20, display: 'flex', flexDirection: 'column', gap: 5, boxSizing: 'border-box'}}>
                    <label style={{fontWeight: 600}}>Amount</label>
                    <div style={{border: "1.5px solid #ccc", width: "100%", height: "45px", borderRadius: "4px", paddingLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        {selectedPaymentObject?.currency} {parseFloat(itemQuantity * selectedPaymentObject?.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                </div>
                <CustomButton loading={buttonLoading} onClick={() => handlePay()}>
                    Submit
                </CustomButton>
            </CustomModal>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20}}>
                <Title>Tariff</Title>
                <div style={{width: "150px"}}>
                    {profile?.userType === "admin" &&
                        <CustomButton onClick={() => setOpenModal(true)}>
                            Add Tariff
                        </CustomButton>
                    }
                </div>
            </div>
            {loading ? 
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularProgress color="success" />
                </Box>
                :
                <ReusableTable 
                    tableHeader={
                        [
                            { id: 'name', label: 'Name', numeric: false },
                            { id: 'type', label: 'Type', numeric: false },
                            { id: 'amount', label: 'Amount', numeric: true },
                            { id: 'currency', label: 'Currency', numeric: false },
                            { id: 'unitOfMeasurement', label: 'Unit of Measurement', numeric: false },
                        ]
                    }
                    tableData={data}
                    tableAction={tableAction} 
                />
            }
        </>
    );
}