import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto"; // NOSONAR
import ChartDataLabel from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";

const PieChart = ({ data, suffix, pageRedirect }) => {
    const chartRef = useRef(); 
    const navigate = useNavigate();

    const options = {
        maintainAspectRatio: false,
        plugins: {
            tooltips: {
                enabled: true,
            },
            datalabels: {
                display: function(context) {
                    return context.dataset.data[context.dataIndex] !== 0;
                },
                formatter: (value, ctx) => {
                    if (suffix) {
                        return value !== 0 ? value + suffix : null;
                    }
                    return value !== 0 ? value : null;
                },
                color: (context) => {
                    const dataIndex = context.dataIndex;
                    const dataValue = context.dataset.data[dataIndex];

                    if(dataValue !== 0) {
                        const dataColors = data.datasets[0].dataColors;
                        return dataColors[dataIndex];
                    }
                },
                font: {
                    weight: "bold",
                    // family: "'Poppins', 'sans-serif'",
                    size: 14,
                },
            },
            legend: {
                display: false,
            },
        },
    };

    return <Pie 
                ref={chartRef}
                data={data} 
                options={options} 
                plugins={[ChartDataLabel]} 
                onClick={(event) => {
                    const chart = chartRef.current; // Access the chart instance
                    if (!chart) return;

                    const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);

                    if (elements.length > 0) {
                        const { index } = elements[0]; // get index of the clicked element
                        const label = data.labels[index];
                        const value = data.datasets[0].data[index];
                        navigate(`/${pageRedirect}?name=${label}`);
                        //alert(`You clicked on ${label} with value ${value}`);
                    }
                }}
            />;
};

PieChart.propTypes = {
	data: PropTypes.any,
};

export default PieChart;
