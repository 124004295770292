import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import CustomInput from "../../components/input/CustomInput";
import SelectInput from "../../components/input/SelectInput";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Receipt from "../../components/receipt/Receipt";
import ReusableTable from "../../components/Table/CustomTable";
import { Grid2 } from "@mui/material";

export default function EditAssetPage() {
    const { id } = useParams();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");

    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [idNumber, setIdNumber] = useState('');
    const [idNumberEdit, setIdNumberEdit] = useState('');
    const [nameEdit, setNameEdit] = useState('');
    const [descriptionEdit, setDescriptionEdit] = useState('');
    const [locationEdit, setLocationEdit] = useState('');
    const [quantityEdit, setQuantityEdit] = useState('');
    const [statusEdit, setStatusEdit] = useState('');
    const [makeEdit, setMakeEdit] = useState('');
    const [modelEdit, setModelEdit] = useState('');
    const [assignedPersonelEdit, setAssignedPersonelEdit] = useState('');
    const [personelPhoneEdit, setPersonelPhoneEdit] = useState('');
    const [personelEmailEdit, setPersonalEmailEdit] = useState('');
    const [machineryTypeEdit, setMachineryTypeEdit] = useState('');

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/assets/id/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result?.code === 200) {
                    const row = result?.data?.asset;
                    setIdNumberEdit(row?.idNumber);
                    setNameEdit(row?.name);
                    setDescriptionEdit(row?.description);
                    setQuantityEdit(row?.quantity);
                    setStatusEdit(row?.status);
                    setLocationEdit(row?.location);
                    setMakeEdit(row?.make);
                    setModelEdit(row?.model);
                    setMachineryTypeEdit(row?.machineryType);
                    setAssignedPersonelEdit(row?.assignedPersonel);
                    setPersonelPhoneEdit(row?.personelPhone);
                    setPersonalEmailEdit(row?.personelEmail);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const handleEdit = () => {
        if (!idNumberEdit || !nameEdit || !descriptionEdit || !locationEdit || !quantityEdit || !statusEdit) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                id: id,
                idNumber: idNumberEdit, 
                name: nameEdit, 
                description: descriptionEdit, 
                location: locationEdit, 
                quantity: quantityEdit, 
                make: makeEdit, 
                model: modelEdit, 
                assignedPersonel: assignedPersonelEdit, 
                personelPhone: personelPhoneEdit, 
                personelEmail: personelEmailEdit,
                machineryType: machineryTypeEdit,
                status: statusEdit
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/assets/edit`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Add Asset</Title>
            </div>
            <div style={{ width: "100%", padding: 20, paddingTop: 40, boxSizing: "border-box", backgroundColor: "white" }}>
                <Grid2 container>
                    <Grid2 size={{ sx: 12, md: 3 }} />
                    <Grid2 size={{ sx: 12, md: 6 }} >
                    <CustomInput
                    label="Asset ID"
                    placeholder="Enter the asset ID"
                    value={idNumberEdit}
                    onChange={(e) => setIdNumberEdit(e.target.value)}
                />
                <CustomInput
                    label="Name"
                    placeholder="Enter the asset name"
                    value={nameEdit}
                    onChange={(e) => setNameEdit(e.target.value)}
                />
                <CustomInput
                            label="Machinery Type"
                            placeholder="Enter the machinery type"
                            value={machineryTypeEdit}
                            onChange={(e) => setMachineryTypeEdit(e.target.value)}
                        />
                <CustomInput
                            label="Vehicle Make"
                            placeholder="Enter the vehicle make"
                            value={makeEdit}
                            onChange={(e) => setMakeEdit(e.target.value)}
                        />
                        <CustomInput
                            label="Vehicle Model"
                            placeholder="Enter the vehicle model"
                            value={modelEdit}
                            onChange={(e) => setModelEdit(e.target.value)}
                        />
                <CustomInput
                    label="Description"
                    placeholder="Enter the asset description"
                    value={descriptionEdit}
                    onChange={(e) => setDescriptionEdit(e.target.value)}
                />
                <CustomInput
                    label="Quantity"
                    placeholder="Enter the asset quantity"
                    value={quantityEdit}
                    onChange={(e) => setQuantityEdit(e.target.value)}
                />
                <SelectInput
                    data={[
                        { id: 1, name: "Operational" },
                        { id: 2, name: "Under Maintenance" },
                        { id: 3, name: "Out of Service" },
                        { id: 4, name: "Under Repair" }
                    ]}
                    label="Status"
                    value={statusEdit}
                    onChange={(e) => {
                        setStatusEdit(e.target.value);
                    }}
                    placeholder="Select Payment"
                />
                <CustomInput
                    label="Location"
                    placeholder="Enter the asset location"
                    value={locationEdit}
                    onChange={(e) => setLocationEdit(e.target.value)}
                />
                        <CustomInput
                            label="Assigned Personel"
                            placeholder="Enter the personel full name"
                            value={assignedPersonelEdit}
                            onChange={(e) => setAssignedPersonelEdit(e.target.value)}
                        />
                        <CustomInput
                            label="Personel Phone"
                            placeholder="Enter the personel phone number"
                            value={personelPhoneEdit}
                            onChange={(e) => setPersonelPhoneEdit(e.target.value)}
                        />
                        <CustomInput
                            label="Personel Email"
                            placeholder="Enter the personel email"
                            value={personelEmailEdit}
                            onChange={(e) => setPersonalEmailEdit(e.target.value)}
                        />
                        <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleEdit()}>
                            Save
                        </CustomButton>
                    </Grid2>
                    <Grid2 size={{ sx: 12, md: 3 }} />
                </Grid2>
            </div>
        </>
    );
}