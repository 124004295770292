import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function SelectInput({ data, name, label, error, onChange, placeholder, value, defaultValue }) {
    console.log(value);
    console.log(defaultValue);
  return (
    <div style={{marginBottom: 20, display: 'flex', flexDirection: 'column', gap: 5}}>
            <label style={{fontWeight: 600}}>{label}</label>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          fullWidth
          //placeholder={placeholder}
          error={error}
          color="success"
          variant="outlined"
          name={name}
          //label={label}
          value={value ? value : defaultValue && defaultValue}
          defaultValue={defaultValue && defaultValue}
          onChange={onChange}
          style={{height: '45px'}}>
            {
                data?.map((item) => (
                    <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                ))
            }
        </Select>
    </div>
  );
}
