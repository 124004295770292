import React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <FooterWrapper>
            <p>&copy; 2024 <Link style={{color: '#1D8A50', textDecoration: 'none'}} to={'https://ereg.ng'}>Powered by <i>eREG Limited</i></Link></p>
        </FooterWrapper>
    )
}

export default Footer;

const FooterWrapper = styled('div')(() => ({
    position: 'relative',
    width: '100%',
    height: '50px',
    borderTop: '1px solid #E1E1E1',
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    textAlign: 'center',
}));