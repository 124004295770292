import { useState, useEffect } from "react";

export default function useFetch(
  initialUrl = "",
  { initialMethod = "GET", initialPayload = null, executeImmediately = false },
  cb = () => null,
  errCb = () => null
) {
  const [url, setUrl] = useState(initialUrl);
  const [method, setMethod] = useState(initialMethod);
  const [payload, setPayload] = useState(initialPayload);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [execute, setExecute] = useState(executeImmediately);
  const [callback, setCallback] = useState(cb);
  const [errorCallback, setErrorCallback] = useState(errCb);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!execute) {
        return;
      }
      setLoading(true);

      try {
        const options = {
          method: method,
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
        };

        if (method !== "GET") {
          options.body = JSON.stringify(payload);
        }

        const response = await fetch(url, options);

        if (!isMounted) {
          // Ignore the response if component has unmounted
          return;
        }

        const json = await response.json();

        if (!response.ok) {
          throw new Error(json.message || "Something went wrong");
        }

        setData(json.data);
        setError(null);
        if (typeof callback === "function") {
          callback();
        }
      } catch (error) {
        setError(error);
        setData(null);
        if (typeof errorCallback === "function") {
          errorCallback(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [url, method, payload, execute, callback, errorCallback]);

  const sendHttpRequest = (url, method, payload, cb, errorCallback) => {
    setUrl(url);
    setMethod(method);
    setPayload(payload);
    setExecute(true);
    setCallback(cb);
    setErrorCallback(errorCallback);
  };

  return { data, error, loading, sendHttpRequest };
}
