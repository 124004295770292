import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";

export default function CustomInput({ size, name, label, error, onChange, placeholder, type, value, defaultValue }) {
    const [showPassword, setShowPassword] = useState(false);
    
    return(
        <div style={{marginBottom: 20, display: 'flex', flexDirection: 'column', gap: 5}}>
            <label style={{fontWeight: 600}}>{label}</label>
            <TextField
                fullWidth
                placeholder={placeholder}
                error={error}
                color="success"
                variant="outlined"
                name={name}
                //label={label}
                value={value && value}
                defaultValue={defaultValue && defaultValue}
                // type={type === 'password' ? (showPassword ? 'text' : 'password') : (type ? type : 'text')}
                type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                onChange={onChange}
                autoComplete="none"
                sx={{'.MuiFormLabel-root[data-shrink=false]': { top: size === "small" && '-10px' || size === "medium" && '-5px' || size === "large" && '0px' || size === undefined && '-5px'} }}
                InputProps={{
                    endAdornment: type === "password" && (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
                            {/* <Iconify color="red" icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                        </IconButton>
                    </InputAdornment>
                    ),
                    style: {
                        height: size === "small" && '35px' || size === "medium" && '45px' || size === "large" && '55px' || size === undefined && '45px',
                    },
                }}
            />
        </div>
    )
}
CustomInput.propTypes = {
    name: PropTypes.any,
    size: PropTypes.any,
    label: PropTypes.any,
    error: PropTypes.any,
    onChange: PropTypes.any,
    placeholder: PropTypes.any,
    type: PropTypes.any,
    value: PropTypes.any,
}
