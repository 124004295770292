import React from "react";
import { Typography } from '@mui/material';

export default function FormTitle(prop) {
    const { variant, color } = prop;
    return(
        <Typography variant={variant ? variant : "h5"} gutterBottom sx={{ mb: 2, mt: 1 }} style={{textAlign: "center", color: color ? color : ""}}>
            {prop.children}
        </Typography>
    );
}