import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import Title from "../../components/text/Title";
import CustomButton from "../../components/button/CustomButton";
import CustomInput from "../../components/input/CustomInput";
import SelectInput from "../../components/input/SelectInput";
import MessageAlert from "../../components/alerts/MessageAlert";
import { handleAlertClose } from '../../components/alerts/alertUtils';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Receipt from "../../components/receipt/Receipt";
import ReusableTable from "../../components/Table/CustomTable";
import { Grid2 } from "@mui/material";

export default function AddAssetPage() {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("error");

    const [buttonLoading, setButtonLoading] = useState(false);
    const [idNumber, setIdNumber] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [quantity, setQuantity] = useState('');
    const [status, setStatus] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [assignedPersonel, setAssignedPersonel] = useState('');
    const [personelPhone, setPersonelPhone] = useState('');
    const [personelEmail, setPersonalEmail] = useState('');
    const [machineryType, setMachineryType] = useState('');

    const handleAdd = () => {
        if (!idNumber || !name || !description || !location || !quantity || !status) {
            setAlertMessage("All fields are required!");
            setAlertType("error");
            setAlertOpen(true);
        } else {
            setButtonLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                idNumber, name, description, location, quantity, make, model, assignedPersonel, personelPhone, personelEmail, machineryType, status
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrl}/api/v1/assets/add`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result?.code === 200) {
                        setAlertMessage(result?.message);
                        setAlertType("success");
                        setAlertOpen(true);
                    } else {
                        setAlertMessage(result?.message);
                        setAlertType("error");
                        setAlertOpen(true);
                    }
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setAlertMessage("An error occured, try again !");
                    setAlertType("error");
                    setAlertOpen(true);
                    setButtonLoading(false);
                });
        }
    }

    return (
        <>
            <MessageAlert
                message={alertMessage}
                open={alertOpen}
                onClose={handleAlertClose(setAlertOpen)}
                type={alertType}
            />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                <Title>Add Asset</Title>
            </div>
            <div style={{ width: "100%", padding: 20, paddingTop: 40, boxSizing: "border-box", backgroundColor: "white" }}>
                <Grid2 container>
                    <Grid2 size={{ sx: 12, md: 3 }} />
                    <Grid2 size={{ sx: 12, md: 6 }} >
                        <CustomInput
                            label="Asset ID"
                            placeholder="Enter the asset ID"
                            value={idNumber}
                            onChange={(e) => setIdNumber(e.target.value)}
                        />
                        <CustomInput
                            label="Name"
                            placeholder="Enter the asset name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <CustomInput
                            label="Machinery Type"
                            placeholder="Enter the machinery type"
                            value={machineryType}
                            onChange={(e) => setMachineryType(e.target.value)}
                        />
                        <CustomInput
                            label="Vehicle Make"
                            placeholder="Enter the vehicle make"
                            value={make}
                            onChange={(e) => setMake(e.target.value)}
                        />
                        <CustomInput
                            label="Vehicle Model"
                            placeholder="Enter the vehicle model"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                        />
                        <CustomInput
                            label="Description"
                            placeholder="Enter the asset description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <CustomInput
                            label="Quantity"
                            placeholder="Enter the asset quantity"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                        <SelectInput
                            data={[
                                { id: 1, name: "Operational" },
                                { id: 2, name: "Under Maintenance" },
                                { id: 3, name: "Out of Service" },
                                { id: 4, name: "Under Repair" }
                            ]}
                            label="Status"
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                            placeholder="Select Payment"
                        />
                        <CustomInput
                            label="Location"
                            placeholder="Enter the asset location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        <CustomInput
                            label="Assigned Personel"
                            placeholder="Enter the personel full name"
                            value={assignedPersonel}
                            onChange={(e) => setAssignedPersonel(e.target.value)}
                        />
                        <CustomInput
                            label="Personel Phone"
                            placeholder="Enter the personel phone number"
                            value={personelPhone}
                            onChange={(e) => setPersonelPhone(e.target.value)}
                        />
                        <CustomInput
                            label="Personel Email"
                            placeholder="Enter the personel email"
                            value={personelEmail}
                            onChange={(e) => setPersonalEmail(e.target.value)}
                        />
                        <CustomButton loading={buttonLoading} disabled={buttonLoading} onClick={() => handleAdd()}>
                            Submit
                        </CustomButton>
                    </Grid2>
                    <Grid2 size={{ sx: 12, md: 3 }} />
                </Grid2>
            </div>
        </>
    );
}